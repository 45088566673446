import { ToastService } from 'src/app/services/toast.service'
import { DeckListImporterComponent } from './deck-list-importer/deck-list-importer.component'
import { take, takeUntil } from 'rxjs/operators'
import { StorageService } from 'src/app/services/storage.service'
import { GlobalsService } from 'src/app/services/globals.service'
import { BehaviorSubject, Subject } from 'rxjs'
import { CardSearchService, ISelectedSet, ISimpleCardExtended, ISimpleCardPrinting } from 'src/app/services/card-search.service'
import { DeckPart, DecksService, ICardMeta, IDeckList } from 'src/app/services/decks.service'
import { faInfo, faCog, faDollarSign, faHistory, faInfoCircle, faList, faFilter, faThLarge, faThList, faListUl, faImage, faFileDownload, faFileUpload, faTimesCircle, faPlus, faLock, faUnlock, faDownload, faUpload, faFish, faShare, faShareAlt } from '@fortawesome/free-solid-svg-icons'
import { Component, HostListener, ViewChild, ElementRef, OnDestroy } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { v4 as uuidv4 } from 'node_modules/uuid'
import _ from 'lodash'
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { DeckTestComponent } from '../deck-test/deck-test.component'
import { DeckSharingService } from '../deck-sharing.service'

export interface DeckBuilderRouteParams {
  deckDocId: string
  versionDocId: string
}
@Component({
  selector: 'app-deck-builder',
  templateUrl: './deck-builder.component.html',
  styleUrls: ['./deck-builder.component.css']
})
export class DeckBuilderComponent implements OnDestroy {
  @ViewChild('cardSearchInput') cardSearchInput: ElementRef
  @ViewChild('newVersionNameModal') newVersionNameModal: ElementRef
  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.modalService.hasOpenModals()) { return }
    switch (event.key) {
      case 'ArrowUp':
        if (this.searchResult.length > 0) {
          this.selectedPrintingIndex = 0
          this.selectedCardIndex === 0 ? this.selectedCardIndex = this.searchResult.length - 1 : this.selectedCardIndex--
        }
        break
      case 'ArrowDown':
        if (this.searchResult.length > 0) {
          this.selectedPrintingIndex = 0
          this.selectedCardIndex === this.searchResult.length - 1 ? this.selectedCardIndex = 0 : this.selectedCardIndex++
        }
        break
      case 'ArrowLeft':
        if (this.searchResult.length > 0) {
          this.selectedPrintingIndex === 0 ? this.selectedPrintingIndex = this.searchResult[this.selectedCardIndex].printings.length - 1 : this.selectedPrintingIndex--
        }
        break
      case 'ArrowRight':
        if (this.searchResult.length > 0) {
          this.selectedPrintingIndex === this.searchResult[this.selectedCardIndex].printings.length - 1 ? this.selectedPrintingIndex = 0 : this.selectedPrintingIndex++
        }
        break
      case 'Enter':
        if (!event.shiftKey && !event.ctrlKey && !event.altKey) { this.addCardToDeck(DeckPart.MAIN) }
        else if (event.ctrlKey) { this.addCardToDeck(DeckPart.SIDEBOARD) }
        else if (event.shiftKey) { this.addCardToDeck(DeckPart.MAYBEBOARD) }
      case 'F3':
        event.preventDefault()
        this.cardSearchInput.nativeElement.focus()
        break
    }

    if (this.searchResult.length > 0) {
      const el = document.getElementById('cardIndex-' + this.selectedCardIndex)
      if (el) el.scrollIntoView()
      const el2 = document.getElementById('printingIndex-' + this.selectedPrintingIndex)
      if (el2) el2.scrollIntoView()
    }

  }
  public DECKPART = DeckPart

  // Icons
  faInfoCircle = faInfoCircle
  faList = faList
  faInfo = faInfo
  faDollarSign = faDollarSign
  faHistory = faHistory
  faCog = faCog
  faFilter = faFilter
  faThLarge = faThLarge
  faThList = faThList
  faFileDownload = faDownload
  faFileUpload = faUpload
  faTimesCircle = faTimesCircle
  faPlus = faPlus
  faLock = faLock
  faUnlock = faUnlock
  iconPlayTest = faFish
  faShare = faShareAlt

  public deckList$: BehaviorSubject<IDeckList> = new BehaviorSubject<IDeckList>(null)
  public workingVersion$: BehaviorSubject<IDeckList> = new BehaviorSubject<IDeckList>(null)

  public selectedCardIndex: number = 0
  public selectedPrintingIndex: number = 0
  public simpleCardList: ISimpleCardExtended[] = [] // origin
  public filteredCards: ISimpleCardExtended[] = [] // filtered origin
  public searchResult: ISimpleCardExtended[] = []
  public newVersionName: string = ''
  private componentWasDestroyed$: Subject<boolean> = new Subject<boolean>()

  public cardFilter: any = {
    show: false,
    color: {
      w: true,
      u: true,
      b: true,
      r: true,
      g: true,
      c: true,
    },
    type: {
      creature: true,
      sorcery: true,
      instant: true,
      enchantment: true,
      artifact: true,
      planeswalker: true,
      land: true,
      tribal: false,
      plane: false,
      phenomenon: false,
      scheme: false,
      conspiracy: false,
      vanguard: false,
      token: false,
    },
    search: {
      set: '',
      string: '',
    },
    searchByName: true,
    cmc: {
      min: 0,
      max: 20,
    },
    showSetsPanel: false,
    setList: this.cardService.setList,
    selectedSets: [
      { name: 'Limited Edition Alpha', code: 'lea' },
      { name: 'Limited Edition Beta', code: 'leb' },
      { name: 'Unlimited Edition', code: '2ed' },
      { name: 'Arabian Nights', code: 'arn' },
      { name: 'Antiquities', code: 'atq' },
      { name: 'Legends', code: 'leg' },
      { name: 'The Dark', code: 'drk' },
      { name: 'Fallen Empires', code: 'fem' },
    ],
    addSet: (event: MouseEvent, code: string, name: string) => {
      event.stopPropagation()
      if (this.cardFilter.selectedSets.filter(s => s.code === code).length === 0) {
        this.cardFilter.selectedSets.push({
          name,
          code
        } as ISelectedSet)
        this.cardFilter.search.set = ''
      }
    },
    removeSet: (i: number) => {
      this.cardFilter.selectedSets.splice(i, 1)
    },
    onChangeCMC: (event: number[]) => {
      this.cardFilter.cmc.min = event[0]
      this.cardFilter.cmc.max = event[1]
      // this.filterCards()
    }
  }
  public mainViews: any[] = [
    {
      text: 'Visual',
      value: 'deck-visual-editor',
      icon: faThLarge,
      tooltip: 'Show visual spoiler',
    },
    {
      text: 'List',
      value: 'deck-list-editor',
      icon: faListUl,
      tooltip: 'Show deck list',
    },
    {
      text: 'Picture',
      value: 'deck-picture',
      icon: faImage,
      tooltip: 'Show/Edit deck picture',
    },
    {
      text: 'Details',
      value: 'deck-details',
      icon: faInfo,
      tooltip: 'Show deck statistics & details',
    },
  ]
  constructor(
    private route: ActivatedRoute,
    private deckService: DecksService,
    private cardService: CardSearchService,
    private globals: GlobalsService,
    private storage: StorageService,
    private modalService: NgbModal,
    private toastService: ToastService,
    private readonly sharing: DeckSharingService,
  ) {
    // fetch all magic cards from realtime database to populate the search list
    // this.cardService.simpleCardList$.subscribe((cards) => {
    //   if (cards !== null) {
    //     this.simpleCardList = cards
    //     this.filterCards()
    //   }
    // })

    // fetch the deck
    this.deckService.getDeckById(this.route.snapshot.params.deckDocId)
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe(async (deck) => {
        const deckOK = await this.deckCheck(deck)
        if (deckOK) {
          // set deckList as current deck
          this.deckList$.next(deck)
          // experimental: define zoomLevel by container width
          // setTimeout(() => {
          //   this.calculateSuitableZoomLevel(deck)
          // }, 5000)
        }
        else {
          const tmpDeck: IDeckList = JSON.parse(JSON.stringify(deck)) as IDeckList
          this.updateDeckMeta(tmpDeck)
        }
      })

    // fetch working version
    this.route.params
      .pipe(takeUntil(this.componentWasDestroyed$))
      .subscribe((params: DeckBuilderRouteParams) => {
        // store version document id
        const versionDocId = params.versionDocId === 'origin' ? null : params.versionDocId
        // fetch deck and start subscription
        this.deckService.getDeckById(this.route.snapshot.params.deckDocId, versionDocId)
          .pipe(takeUntil(this.componentWasDestroyed$))
          .subscribe(async (deck) => {
            // set working version to the version deck loaded
            this.workingVersion$.next(deck)
          })
      })


  }
  ngOnDestroy(): void {
    this.componentWasDestroyed$.next(true)
  }

  public shareVersion(): void {
    let workingDeck = this.workingVersion$.getValue()
    if (workingDeck.sharingId) {
      // copy url to clipboard
      navigator.clipboard.writeText(`${window.location.origin}/deck-viewer/${workingDeck.sharingId}`)
      .then(
        () => this.toastService.show('Sharing url copied to the clipboard', { classname: 'success-toast' }),
        () => this.toastService.show('Could not copy sharing url to clipboard', { classname: 'error-toast' })
      )
    }
    else {
      this.sharing.createSharingUrl(workingDeck)
    }
  }

  public changeView(view: any): void {
    this.deckService.deckBuilderMainView = view.value
  }
  private calculateSuitableZoomLevel(deck: IDeckList): void {
    const deckViewElement = document.getElementById('mainView')
    const rect = deckViewElement.getClientRects()

    // calculate zoom level by:
    // 1) Dividing the container pixel width > rem
    // 2) Divide it into 12 section
    // 3) Finally make it a decimal number
    let targetZoomLevel = (((rect[0].width / 16) / 12) / 10)

    // make sure the zoom take the zoom min and max into account
    if (targetZoomLevel < 0.2) { targetZoomLevel = 2 }
    if (targetZoomLevel > 1.5) { targetZoomLevel = 1.5 }

    // round the zoom level up to closes decimal
    const roundedTargetZoomLevel = Math.round(targetZoomLevel * 10) / 10

    // if new zoom level not as current, do update
    if (deck.cardZoomLevel !== roundedTargetZoomLevel) {
      deck.cardZoomLevel = roundedTargetZoomLevel
      this.deckService.updateDeckProperty(this.deckDocId, 'cardZoomLevel', roundedTargetZoomLevel)
    }
  }
  private async deckCheck(deck: IDeckList): Promise<boolean> {
    // check if deck needs meta update

    let seenMain = new Set()
    let mainHasDuplicates: boolean = false
    if (deck.main && deck.main.length > 0) {
      mainHasDuplicates = deck.main.some((currentObject) => {
        return seenMain.size === seenMain.add(currentObject.metaUuid).size
      })
    }
    if (mainHasDuplicates) {
      console.log('main deck contains duplicate metaUuids',)
      return false
    }

    let seenSB = new Set()
    let sideboardHasDuplicates: boolean = false
    if (deck.sideboard && deck.sideboard.length > 0) {
      sideboardHasDuplicates = deck.sideboard.some((currentObject) => {
        return seenSB.size === seenSB.add(currentObject.metaUuid).size
      })
    }
    if (sideboardHasDuplicates) {
      console.log('sideboard contains duplicate metaUuids',)
      return false
    }

    let seenMB = new Set()
    let maybeboardHasDuplicates: boolean = false
    if (deck.maybeboard && deck.maybeboard.length > 0) {
      maybeboardHasDuplicates = deck.maybeboard.some((currentObject) => {
        return seenMB.size === seenMB.add(currentObject.metaUuid).size
      })
    }
    if (maybeboardHasDuplicates) {
      console.log('maybies contains duplicate metaUuids',)
      return false
    }

    if (deck.timestampCreated === undefined) {
      console.log('timestamp missing')
      return false
    }

    if (deck.maybeboard === undefined) {
      console.log('maybeboard missing')
      return false
    }

    if (deck.main.filter(c => c.colors === undefined || c.setCode === undefined || c.cmc === undefined).length > 0) {
      console.log('main deck contains missing color, setcode or cmc')
      return false
    }

    if (deck.sideboard.filter(c => c.colors === undefined || c.setCode === undefined || c.cmc === undefined).length > 0) {
      console.log('sideboard contains missing color, setcode or cmc')
      return false
    }

    if (deck.maybeboard.filter(c => c.colors === undefined || c.setCode === undefined || c.cmc === undefined).length > 0) {
      console.log('maybeboard contains missing color, setcode or cmc')
      return false
    }

    if (deck.isPrivate === undefined || deck.isPrivate === null) {
      console.log('deck missing property isPrivate')
      return false
    }

    if (deck.isLocked === undefined || deck.isLocked === null) {
      console.log('deck missing property isLocked')
      return false
    }

    if (deck.versions === undefined) {
      console.log('versions array missing')
      return false
    }

    return true
  }
  private async updateDeckMeta(tmpDeck: IDeckList): Promise<boolean> {
    this.globals.isBusy.message = 'Updating Deck Meta, please wait...'
    this.globals.isBusy.showMessage = true
    this.globals.isBusy.status = true

    console.log('about to update metas for deck', JSON.parse(JSON.stringify(tmpDeck)))

    const cardMetas: ICardMeta[] = []
    const cards = _.concat(tmpDeck.main ? tmpDeck.main : [], tmpDeck.sideboard ? tmpDeck.sideboard : [], tmpDeck.maybeboard ? tmpDeck.maybeboard : [])
    console.log(cards)
    if (cards.filter(c => !c.colors || !c.setCode || !c.cmc).length > 0) {
      console.log('...some cards are missing needed meta data, fetching needed meta datas')
      tmpDeck.cardIds = _.map(_.uniqBy(_.concat(tmpDeck?.main, tmpDeck?.sideboard, tmpDeck?.maybeboard), 'scryfallId'), 'scryfallId')
      for await (const cardId of tmpDeck.cardIds) {
        const cardData = await this.cardService.getCardById(cardId)
        if (cardData !== null) {
          const cardMeta: ICardMeta = this.deckService.getCardMetaFromCardObject(cardData)
          cardMetas.push(cardMeta)
        }
      }
    }

    // update all card in main deck
    if (tmpDeck.main && tmpDeck.main.length > 0) {
      // check if update of meta is needed
      if (tmpDeck.main.filter(c => !c.colors || !c.setCode || !c.cmc).length > 0) {
        console.log('...a number of cards in main deck needs new meta data, fetching info')
        const cardsMainDeck: ICardMeta[] = []
        for await (const card of tmpDeck.main) {
          const newCard = this.updateCardMeta(card, cardMetas)
          cardsMainDeck.push(newCard)
        }
        tmpDeck.main = cardsMainDeck
      }
    }
    else {
      tmpDeck.main = []
    }

    // update all card in sideboard
    if (tmpDeck.sideboard && tmpDeck.sideboard.length > 0) {
      // check if update of meta is needed
      if (tmpDeck.sideboard.filter(c => !c.colors || !c.setCode || !c.cmc).length > 0) {
        console.log('...a number of cards in sideboard needs new meta data, fetching info')
        const cardsSideboard: ICardMeta[] = []
        for await (const card of tmpDeck.sideboard) {
          const newCard = this.updateCardMeta(card, cardMetas)
          cardsSideboard.push(newCard)
        }
        tmpDeck.sideboard = cardsSideboard
      }
    }
    else {
      tmpDeck.sideboard = []
    }

    // update all card in maybeboard
    if (tmpDeck.maybeboard && tmpDeck.maybeboard.length > 0) {
      // check if update of meta is needed
      if (tmpDeck.maybeboard.filter(c => !c.colors || !c.setCode || !c.cmc).length > 0) {
        console.log('...a number of cards in main deck needs new meta data, fetching info')
        const cardsMaybeBoard: ICardMeta[] = []
        for await (const card of tmpDeck.maybeboard) {
          const newCard = this.updateCardMeta(card, cardMetas)
          cardsMaybeBoard.push(newCard)
        }
        tmpDeck.maybeboard = cardsMaybeBoard
      }
    }
    else {
      tmpDeck.maybeboard = []
    }

    // check deck list meta
    if (!tmpDeck.timestampCreated) {
      console.log('...timestampCreated missing, adding this')
      tmpDeck.timestampCreated = parseInt((new Date().getTime() / 1000).toFixed(0))
    }
    if (!tmpDeck.colors) {
      console.log('...colors array missing, adding this')
      tmpDeck.colors = []
    }
    if (!tmpDeck.deckFolderDocId) {
      console.log('...deckFolderDocId missing, adding this')
      tmpDeck.deckFolderDocId = null
    }
    if (!tmpDeck.cardZoomLevel) {
      console.log('...cardZoomLevel missing, adding this')
      tmpDeck.cardZoomLevel = 1
    }
    if (!tmpDeck.isPrivate) {
      console.log('...isPrivate missing, adding this')
      tmpDeck.isPrivate = false
    }
    if (!tmpDeck.isLocked) {
      console.log('...isLocked missing, adding this')
      tmpDeck.isLocked = tmpDeck.eventDocIds.length > 0 ? true : false
    }
    if (!tmpDeck.versions) {
      console.log('...versions missing, adding this')
      tmpDeck.versions = []
    }

    this.globals.isBusy.showMessage = false
    this.globals.isBusy.status = false

    console.log('calling saveDeckList', JSON.parse(JSON.stringify(tmpDeck)))
    this.deckService.saveDeckList(tmpDeck)

    return true
  }
  private updateCardMeta(card: ICardMeta, cardMetas: ICardMeta[]): ICardMeta {
    const cardMeta: ICardMeta = cardMetas.find(c => c.scryfallId === card.scryfallId)
    if (cardMeta) {
      const tmpCard: ICardMeta = JSON.parse(JSON.stringify(cardMeta))
      tmpCard.coords = card.coords ? card.coords : { x: 10, y: 10 }
      tmpCard.metaUuid = JSON.parse(JSON.stringify(uuidv4()))
      return tmpCard
    }
  }

  private getCardType(typeLine: string): string[] {
    const types: string[] = []
    if (typeLine.toLowerCase().includes('land')) types.push('Lands')
    if (typeLine.toLowerCase().includes('creature')) types.push('Creatures')
    if (typeLine.toLowerCase().includes('instant')) types.push('Instants')
    if (typeLine.toLowerCase().includes('sorcery')) types.push('Sorceries')
    if (typeLine.toLowerCase().includes('artifact')) types.push('Artifacts')
    if (typeLine.toLowerCase().includes('enchantment')) types.push('Enchantments')
    if (typeLine.toLowerCase().includes('planeswalker')) types.push('Planeswalkers')

    return types
  }
  public async onSearch() {

    if (this.cardFilter.search.string.length >= 3) {

      this.searchResult = []
      this.filteredCards = []

      this.cardService.getCardsByString(this.cardFilter.search.string)
        .then(async (data) => {
          const cards: ISimpleCardExtended[] = []

          for await (const card of data) {

            const storedCard = cards.find(c => c.name === card.name)

            if (storedCard === undefined) {
              const newCard: ISimpleCardExtended = {
                colors: card.colors,
                convertedManaCost: card.cmc,
                name: card.name,
                manaCost: card.mana_cost,
                type: card.type_line,
                types: this.getCardType(card.type_line),
                text: card.oracle_text,
                isColorless: card.colors ? card.colors.length === 0 : false,
                isMulticolored: card.colors ? card.colors.length > 1 : false,
                isWhite: card.colors ? card.colors.includes('W') : false,
                isBlue: card.colors ? card.colors.includes('U') : false,
                isBlack: card.colors ? card.colors.includes('B') : false,
                isRed: card.colors ? card.colors.includes('R') : false,
                isGreen: card.colors ? card.colors.includes('G') : false,
                printings: [{
                  keyruneCode: card.set ? card.set.toUpperCase() : 'DEFAULT',
                  layout: card.layout,
                  scryfallId: card.id,
                  scryfallIllustrationId: card.illustration_id,
                  set: card.set_name,
                  setCode: card.set,
                  uuid: card.id,
                  iconUrl: this.cardService.cleanSetList.find(c => c.code === card.set) ? this.cardService.cleanSetList.find(c => c.code === card.set).icon_svg_uri : '',
                }]
              }
              cards.push(newCard)
            }
            else {
              storedCard.printings.push({
                keyruneCode: card.set ? card.set.toUpperCase() : 'DEFAULT',
                layout: card.layout,
                scryfallId: card.id,
                scryfallIllustrationId: card.illustration_id,
                set: card.set_name,
                setCode: card.set,
                uuid: card.id,
                iconUrl: this.cardService.cleanSetList.find(c => c.code === card.set) ? this.cardService.cleanSetList.find(c => c.code === card.set).icon_svg_uri : '',
              })
            }

          }

          // sort cards
          cards.sort((a, b) => a.name > b.name ? 1 : b.name > a.name ? -1 : 0)

          this.simpleCardList = cards

          // filter cardds
          // await this.filterCards()
          this.filteredCards = cards
          this.searchResult = cards

          // console.log(this.searchResult)

          // reset selection
          this.selectedCardIndex = 0
          this.selectedPrintingIndex = 0

        })
    }


    // if (this.cardFilter.searchByName) {
    //   this.searchResult = this.filteredCards
    //     .filter(c => c.name.toLowerCase().replace(/([ ,])/g, '')
    //       .indexOf(this.cardFilter.search.string.toLowerCase().replace(/([ ,])/g, '')) > -1)
    //     .slice(0, 20)
    // }
    // else {
    //   this.searchResult = this.filteredCards
    //     .filter(c => c.text.toLowerCase().replace(/([ ,])/g, '')
    //       .indexOf(this.cardFilter.search.string.toLowerCase().replace(/([ ,])/g, '')) > -1)
    //     .slice(0, 20)
    // }


  }
  public async filterCards(): Promise<boolean> {

    this.selectedCardIndex = 0
    this.selectedPrintingIndex = 0

    // skip if no card has been added to the card list (no scryfall search has been perfromed)
    if (this.simpleCardList.length === 0) return

    // create a first list with cards sorted by name
    let filteredCards: ISimpleCardExtended[] = JSON.parse(JSON.stringify(this.simpleCardList))

    // filter on card types
    filteredCards = filteredCards.filter(card => {
      for (const type of Object.keys(this.cardFilter.type)) {
        if (card.type.toLowerCase().includes(type)) { return this.cardFilter.type[type] }
      }
    })

    // filter on sets
    if (this.cardFilter.selectedSets.length > 0) {
      filteredCards = filteredCards.filter(card => {
        for (const set of this.cardFilter.selectedSets) {
          if (card.printings.map(p => p.setCode.toLowerCase()).includes(set.code.toLowerCase())) {
            return true
          }
        }
        return false
      })
    }

    // filter on color
    filteredCards = filteredCards.filter(c => {
      if (c.colors.length === 0 && this.cardFilter.color.c) {
        return true
      }
      if (c.colors.length === 1) {
        return this.cardFilter.color[c.colors[0].toLowerCase()]
      }
      if (c.colors && c.colors.length > 1) {
        for (const color in c.colors) {
          if (this.cardFilter.color[color.toLowerCase()]) {
            return true
          }
        }
        return false
      }
      return false
    })

    // filter on cmc
    filteredCards = filteredCards.filter(c => c.convertedManaCost >= this.cardFilter.cmc.min && c.convertedManaCost <= this.cardFilter.cmc.max)

    // update the public filtered card list
    this.filteredCards = filteredCards

    // call search on the newly created filtered list
    // this.onSearch()
    this.searchResult = this.filteredCards

    return true

  }
  public importDeckList(deck: IDeckList): void {
    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'xl',
    }

    const modalRef: NgbModalRef = this.modalService.open(DeckListImporterComponent, modalOptions)
    modalRef.componentInstance.deckList = deck
    modalRef.closed.pipe(take(1)).subscribe((result: IDeckList) => {
      this.deckService.saveDeckList(result)
    })
  }
  public downloadDeckList(deck: IDeckList): void {
    this.storage.downloadDeckList(deck)
  }
  public addCardToDeck(deckPart: DeckPart): void {
    if (this.workingVersion$.getValue().isLocked) {
      this.toastService.show('Deck is LOCKED, no card added', { classname: 'error-toast', delay: 2000 })
      return
    }
    this.cardService.getCardById(this.selectedCardScryfallId).then(card => {
      if (card !== null) {

        const cardMeta: ICardMeta = this.deckService.getCardMetaFromCardObject(card)
        const deck = this.workingVersion$.getValue()

        // add new coordinates if card is added to main deck
        if (deckPart === DeckPart.MAIN) { cardMeta.coords = this.deckService.calculateCoords(deck.main.length, deck.cardZoomLevel) }

        deck[deckPart].push(cardMeta)
        this.deckService.saveDeckList(deck)
        // const deckDocId = this.deckList$.getValue().docId
        // this.deckService.addCardToDeck(deckDocId, cardMeta, deckPart)
      }
    })
  }
  public generateVersion(): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: true,
      keyboard: true,
      size: 'sm',
    }

    // get the original deck
    const deck = this.deckList$.getValue()

    this.modalService.open(this.newVersionNameModal, modalOptions).result
      .then(
        (result) => {
          this.deckService.generateVersion(deck, this.newVersionName)
        },
        (reason) => {

        }
      )

  }
  public openGoldFishMode(): void {
    const options: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
      windowClass: 'modal-goldfish'
    }
    const modalRef: NgbModalRef = this.modalService.open(DeckTestComponent, options)
    modalRef.componentInstance.deck = this.workingVersion$.getValue()
  }
  public get mainView(): string {
    return this.deckService.deckBuilderMainView
  }
  public get selectedCardImageUri(): string {
    return this.searchResult.length > 0 ? 'https://api.scryfall.com/cards/' + this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].scryfallId + '?format=image' : null
  }
  public get showCardImage(): boolean {
    return this.searchResult.length > 0
      ? this.searchResult.length > 0 && this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].scryfallIllustrationId !== ''
      : null
  }
  public get showSetSelector(): boolean {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings && this.searchResult[this.selectedCardIndex].printings.length > 0
      : null
  }
  public get selectedCardSetCode(): string {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].setCode.toUpperCase()
      : null
  }
  public get selectedCardSet(): string {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].set
      : null
  }
  public get selectedCardPrintings(): ISimpleCardPrinting[] {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings
      : null
  }
  public get selectedCardLayout(): string {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].layout
      : null
  }
  public get selectedCardUUID(): string {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].uuid
      : null
  }
  public get selectedCardScryfallId(): string {
    return this.searchResult.length > 0
      ? this.searchResult[this.selectedCardIndex] && this.searchResult[this.selectedCardIndex].printings[this.selectedPrintingIndex].scryfallId
      : null
  }
  public get selectedDeckVersionName(): string {
    return this.deckList$.getValue().name === this.workingVersion$.getValue().name ? 'origin' : this.workingVersion$.getValue().name
  }
  public get isVersion(): boolean {
    return this.route.snapshot.params.versionDocId !== undefined
  }
  public get deckDocId(): string {
    return this.route.snapshot.params.deckDocId
  }
  public get newVersionNameValid(): boolean {
    const deck = this.deckList$.getValue()
    const versionCheck = deck.versions === undefined || deck.versions.findIndex(i => i.versionName === this.newVersionName) === -1
    return this.newVersionName.length >= 5 && this.newVersionName.toLowerCase() !== deck.name.toLowerCase() && versionCheck
  }

}

