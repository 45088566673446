import { SettingsComponent } from './../modals/settings/settings.component'
import { AuthService } from 'src/app/services/auth.service'
import { Router } from '@angular/router'
import { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faBug, faComment, faHandshake, faHeart, faImage, faQrcode, faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { GlobalsService } from 'src/app/services'
import { Component, AfterViewInit, ViewChild, ElementRef } from '@angular/core'
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap'
import { PlayerIdQrcodeComponent } from '../players/player-id-qrcode/player-id-qrcode.component'
import { QRScannerComponent } from 'src/app/components/modals/qrscanner/qrscanner.component';
import { MatchService } from 'src/app/services/match.service'
import { BehaviorSubject } from 'rxjs'
import { ReportIssueComponent } from '../app-structure/report-issue/report-issue.component'
import { MatchReportingService } from 'src/app/private/tournament/services/match-reporting.service'
import { tolCrossingSwords } from 'src/assets/font-icons/crossing-swords-icon'
import * as confetti from 'canvas-confetti'
export interface IAppLink {
  text: string
  routerLink: string
  icon: IconDefinition
}

interface IMatchMeta {
  docId: string
  opponentName: string
  eventDocId: string
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements AfterViewInit {
  @ViewChild('stripeReturn') stripeReturn: ElementRef
  @ViewChild('stripeRefresh') stripeRefresh: ElementRef
  @ViewChild('matchSelection') matchSelection: ElementRef

  faGithub = faGithub
  faComment = faComment
  faImage = faImage
  faFistRaised = tolCrossingSwords
  faTrophy = faTrophy
  faQrcode = faQrcode
  faReport = faHandshake
  faBug = faBug

  public appLinks: IAppLink[] = [
    {
      text: 'Messages',
      icon: faComment,
      routerLink: '/messages',
    },
    {
      text: 'Deckster',
      icon: faImage,
      routerLink: '/deck-photos/deckster',
    },
    {
      text: 'Matches',
      icon: tolCrossingSwords,
      routerLink: '/matches',
    },
    {
      text: 'Events',
      icon: faTrophy,
      routerLink: '/events',
    },
    {
      text: 'Life Counter',
      icon: faHeart,
      routerLink: '/life-counter',
    }
  ]
  public matchesToReport$: BehaviorSubject<IMatchMeta[]> = new BehaviorSubject<IMatchMeta[]>(null)

  constructor(
    public globals: GlobalsService,
    private router: Router,
    private modalService: NgbModal,
    private auth: AuthService,
    private matchService: MatchService,
    private reporting: MatchReportingService,
  ) { }

  ngAfterViewInit(): void {
    const url = this.router.url
    switch (url) {
      case '/stripe-connect-account-onboarding-refresh':
        // this.openModal('stripe-refresh')
        this.openSettings('payments')
        break
      case '/stripe-connect-account-onboarding-return':
        // this.openModal('stripe-return')
        this.openSettings('payments')
        break
    }
  }

  private openSettings(startupView: string): void {
    const modalOptions: NgbModalOptions = {
      centered: false,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'xl',
    }
    console.log('open settings > payments')
    const modalRef = this.modalService.open(SettingsComponent, modalOptions)
    modalRef.componentInstance.startupView = startupView

  }

  private openModal(modalName: 'stripe-refresh' | 'stripe-return' | 'match-selector', centered: boolean = false) {
    const modalOptions: NgbModalOptions = {
      centered: centered,
      animation: true,
      backdrop: 'static',
      keyboard: false,
      size: 'sm',
    }

    let content: ElementRef

    switch (modalName) {
      case 'stripe-refresh':
        content = this.stripeRefresh
        break

      case 'stripe-return':
        content = this.stripeReturn
        break

      case 'match-selector':
        content = this.matchSelection
        break
    }

    console.log('open content', content)
    return this.modalService.open(content, modalOptions)
  }

  public get hasRedirectUrl(): boolean {
    return this.auth.user.stripe && this.auth.user.stripe.account_links && this.auth.user.stripe.account_links.url
  }

  public redirectToStripe(): void {
    window.location.replace(this.auth.user.stripe.account_links.url)
  }

  public closeWindow(): void {
    window.close()
  }

  public reportIssue(): void {
    const componentRef = this.modalService.open(ReportIssueComponent, {
      size: 'md',
      keyboard: true,
      centered: false,
      backdrop: 'static',
    })
    componentRef.componentInstance.label = 'bug'
  }

  public openPlayerId(): void {

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: false,
      size: 'lg',
      windowClass: 'player-id-modal',
    }

    const modal = this.modalService.open(PlayerIdQrcodeComponent, modalOptions)
    modal.componentInstance.playerDocId = this.auth.user.playerId
    modal.result
      .then(() => {}, () => {})
      .catch((error) => console.log(error))

  }

  public reportMatch(): void {

    const modalOptions: NgbModalOptions = {
      centered: true,
      animation: true,
      backdrop: true,
      keyboard: false,
      size: 'lg',
    }

    const modal = this.modalService.open(QRScannerComponent, modalOptions)
    modal.componentInstance.playerDocId = this.auth.user.playerId
    modal.result
      .then(async (r) => {
        // check if result contains a player document reference
        if (r.includes('playerDocId')) {
          const matches = await this.matchService.getMatchesAgainstPlayerDocId(r.replace('playerDocId.', ''))
          if (matches.length > 0) {
            if (matches.length > 1) {
              console.log(matches)
              const matcheMeta: IMatchMeta[] = matches.map(i => {
                const opponentIs = i.player1.playerDocId === this.auth.user.playerId ? 'player2' : 'player1'
                return {
                  docId: i.docId,
                  eventDocId: i.eventDocId,
                  opponentName: i[opponentIs].displayName,
                } as IMatchMeta
              })
              this.matchesToReport$.next(matcheMeta)
              const modal = this.openModal('match-selector', true)
              modal.result.then(
                (matchDocId) => {
                  this.reporting.openReportSlip({ matchData: matches.find(i => i.docId === matchDocId), isOrganizer: false, matchDocId: matchDocId })
                  // this.matchService.setShowReportSlip(matchDocId)
                },
                () => {}
              )
            }
            else {
              this.reporting.openReportSlip({ matchData: matches[0], isOrganizer: false, matchDocId: matches[0].docId })
              // this.matchService.setShowReportSlip(response[0].docId)
            }
          }
        }
      }, () => {})
      .catch((error) => console.log(error))

  }

  @ViewChild('congrats', { static: false }) congrats: ElementRef
  private counter
  public easterCounter: number = 0
  public foundIt: boolean = false
  public onEasterPress(): void {
    if (this.counter) {
      console.log('...counter clearing reset')
      window.clearTimeout(this.counter)
    }
    console.log('...easter counter pressed')
    this.easterCounter++
    console.log('...counter at', this.easterCounter)

    this.counter = setTimeout(() => {
      window.clearTimeout(this.counter)
      this.easterCounter = 0
      console.log('...easter counter reset')
    }, 3000)

    if (this.easterCounter >= 5) {
      let element = document.getElementById('confettiCanvase')
      element.classList.remove('hidden')
      if (element) {

        const confettiCanon = confetti.create(element, {
          resize: true,
        })
        const count = 200
        const defaults = {
          origin: { y: 0.75 }
        }
        const fire = (particleRatio, opts) => {
          confettiCanon({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio)
          })
        }
        
        fire(0.25, {
          spread: 26,
          startVelocity: 55,
        })
        fire(0.2, {
          spread: 60,
        })
        fire(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8
        })
        fire(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2
        })
        fire(0.1, {
          spread: 120,
          startVelocity: 45,
        })


      }
      this.easterCounter = 0
      this.foundIt = true
      
    }
  }


}
