import { PlayerMetaService } from '../../../services/players/player-meta.service'
import { PlayerNameService } from '../../../services/players/player-name.service'
import { MessagesService, MessageType } from '../../../services/messages.service'
import { AngularFireFunctions } from '@angular/fire/compat/functions'
import { firstValueFrom, skip, take } from 'rxjs'
import { AuthService } from '../../../services/auth.service'
import { AngularFirestore } from '@angular/fire/compat/firestore'
import { Injectable } from '@angular/core'
import { IDeckSubmission, IEventDetails, IEventLog, IEventPlayerDetails, IEventTeam, IInvitedPlayer, IPlayerMini, IWaitListPlayer } from 'tolaria-cloud-functions/src/_interfaces'
import { EventPlayer } from '../classes/event-player.class'
import { IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces'
import { v4 as uuidv4 } from 'node_modules/uuid'
import * as firestore from 'firebase/firestore'
import { ToastService } from 'src/app/services/toast.service'
import { IEventPlayerMeta, IEventTeamMeta, TournamentDataHelperService } from './tournament-data-helper.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { TournamentManagementPlayerTeamConfigComponent } from '../pages/tournament-management/tournament-management-players/tournament-management-players-enroll/tournament-management-player-team-config/tournament-management-player-team-config.component'
import { TournamentManagementNewTeamComponent } from '../pages/tournament-management/tournament-management-players/tournament-management-new-team/tournament-management-new-team.component'
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service'
import { TournamentManagementTeamConfigComponent } from '../pages/tournament-management/tournament-management-players/tournament-management-team-config/tournament-management-team-config.component'
import { ModelProcess } from './helpers/model-processing.helper'
import { TournamentDropActionsComponent } from '../components/tournament-drop-actions/tournament-drop-actions.component'
import { TournamentMatchService } from './tournament-match.service'


export interface IEmailInvite {
  email: string
  playerDocId: string
  playerUid: string
}

@Injectable({
  providedIn: 'root'
})
export class TournamentManagementPlayersService {

  public isBusy: boolean = false
  public busyText = {
    title: '',
    message: '',
  }

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly playerNameService: PlayerNameService,
    private readonly authService: AuthService,
    private readonly functions: AngularFireFunctions,
    private readonly messageService: MessagesService,
    private readonly playerMetaService: PlayerMetaService,
    private readonly toast: ToastService,
    private readonly modalService: NgbModal,
    private readonly data: TournamentDataHelperService,
    private readonly matches: TournamentMatchService,
    private readonly confirm: MessageModalService,
  ) {

  }

  /**
   * Toggle the dropped status of a player in a given tournament
   *
   * @param state boolean --> the state to be set for the property dropped
   * @param eventDocId string --> the event document id
   * @param playerDocIds string[] --> the event player document id
   * @returns Promise<IPromiseResponse>
   */
  public setPlayerDroppedState(state: boolean, eventDocId: string, playerDocIds: string[]): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      // event document reference
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)

      // create write batch
      const batch = this.firestore.firestore.batch()

      // get player performing the action
      const currentPlayer = this.playerNameService.currentPlayersMini

      // loop through all player document ids to change drop state
      for (const playerDocId of playerDocIds) {
        // get player being dropped
        const playerBeingDropped = this.data.players$.getValue().find(i => i.eventPlayer.playerDocId === playerDocId).eventPlayer

        // add drop state update
        batch.update(eventDocRef.collection('players').doc(playerDocId).ref, { dropped: state })

        // add event log entry
        const logText: IEventLog = {
          type: 'attending',
          timestamp: firestore.Timestamp.now().seconds,
          text: state
            ? `${currentPlayer.name.display} <span class="text-bold text-danger">DROPPED</span> ${playerBeingDropped.name}`
            : `${currentPlayer.name.display} <span class="text-bold text-success">UNDROPPED</span> ${playerBeingDropped.name}`,
          metadata: {
            playerDocId: playerBeingDropped.playerDocId,
            playerUid: playerBeingDropped.playerUid,
            updatedByPlayerId: currentPlayer.id,
            updatedByPlayerUid: currentPlayer.uid,
            dropping: state,
            undropping: !state,
          }
        }
        // add write of log document
        batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      }

      // commit batch writes
      batch
        .commit()
        .then(() => {
          this.toast.show(`Player dropped state changed to ${state ? 'dropped' : 'NOT dropped'}`, { classname: 'success-toast' })
          resolve({
            status: true,
            text: 'Success'
          })
        })
        .catch((e) => {
          console.log('[TournamentManagementPlayersService] --> dropping player: ', e)
          this.toast.show(e, { classname: 'error-toast', delay: 5000 })
          resolve({
            status: false,
            text: e,
          })
        })

    })


  }

  /**
   * Perform a check-in action for the given player in the given event
   *
   * @param state boolean --> the check-in state
   * @param eventDocId string
   * @param playerDocIds string[]
   * @returns Promise<IPromiseResponse>
   */
  public setPlayerCheckInState(state: boolean, eventDocId: string, playerDocIds: string[]): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // create batch for updates and writes
      const batch = this.firestore.firestore.batch()

      // create reference to event document
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)

      // loop through all player document ids
      for (const playerDocId of playerDocIds) {

        // update player check-in state
        batch.update(eventDocRef.collection('players').doc(playerDocId).ref, {
          hasCheckedIn: state
        })

        // get player performing the action and player being dropped
        const currentPlayer = this.playerNameService.currentPlayersMini
        const playerUpdated = this.data.players$.getValue().find(i => i.eventPlayer.playerDocId === playerDocId).eventPlayer

        // add event log entry
        const logText: IEventLog = {
          type: 'check-in',
          timestamp: firestore.Timestamp.now().seconds,
          text: state
            ? `${currentPlayer.name.display} flagged ${playerUpdated.name} as <span class="text-bold text-success">CHECKED-IN</span>`
            : `${currentPlayer.name.display} removed the check-in flag for ${playerUpdated.name}`,
          metadata: {
            playerDocId: playerUpdated.playerDocId,
            playerUid: playerUpdated.playerUid,
            updatedByPlayerId: currentPlayer.id,
            updatedByPlayerUid: currentPlayer.uid,
            checkedIn: state,
          }
        }
        // add write of log document
        batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      }



      // perform updates and writes
      batch.commit()
        .then(() => resolve({ status: true, text: `Player check-in state changed to ${state ? 'checked-in' : 'NOT checked-in'}` }))
        .catch((e) => resolve({ status: false, text: JSON.stringify(e) }))

    })
  }

  /**
   * Invite one or more players to an event.
   * An email will be sent to each invitee and for existing Tolaria users,
   * a invitation message will also be posted in the message group for the event.
   *
   * @param emails IEmailInvite[] - array all the invitees
   * @param event IEventDetails - tournament document
   * @param emailBody string - message body
   * @returns Promise<IPromiseResponse>
   */
  public invitePlayers(emails: IEmailInvite[], event: IEventDetails, emailBody: string): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      this.isBusy = true
      this.busyText = {
        title: 'Sending',
        message: `Please wait, sending invitations to ${emails.length} ${emails.length === 1 ? 'email address' : 'email addresses' }...`
      }

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0
      const eventRef = this.firestore.collection('events').doc(event.docId)
      let writeRef: any = null

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())



      // get the initiator of this method
      const invitationBy = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // create an array to hold email errors
      const emailErrors: string[] = []

      // loop through all emails and perform the needed actions
      for await (const email of emails) {
        // lowercase email address
        email.email = email.email.toLowerCase()

        // add update to event document property for invited emails
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedEmails: firestore.arrayUnion(email)
        })

        // create the document data
        const invitedPlayer: IInvitedPlayer = {
          createdAt: firestore.Timestamp.now().seconds,
          email: email.email,
          accepted: false,
          declined: false,
          isRegistered: email.playerDocId !== null,
          playerUid: email.playerUid === null ? '' : email.playerUid,
          playerDocId: email.playerDocId === null ? '' : email.playerDocId,
          sendMessage: email.playerDocId === null ? false : true,
          sendEmail: true,
          eventDocId: event.docId,
          messageDocId: email.playerDocId === null ? null : uuidv4(),
        }
        // add write of event > invitations > document
        writeRef = eventRef.collection('invitations').doc(email.email).ref
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(writeRef, invitedPlayer)


        // log activity to the event log
        const logText: IEventLog = {
          type: 'invitation',
          timestamp: firestore.Timestamp.now().seconds,
          text: `${email.email} invited to the event by ${invitationBy.name.first} ${invitationBy.name.last}`,
          metadata: {
            email: email.email,
            playerDocId: email.playerDocId,
            playerUid: email.playerUid,
            invitedByDocId: invitationBy.id,
            invitedByName: `${invitationBy.name.first} ${invitationBy.name.last}`,
          }
        }
        // add write of log document
        writeRef = eventRef.collection('log').doc(`log-${uuidv4()}`).ref
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(writeRef, logText)


        // check if registered Tolaria player
        if (email.playerDocId !== null) {

          // add write of invitation message
          if (invitedPlayer.sendMessage) {

            // call method in the message service to post the message
            this.messageService.postChatMessage(
              'event-invitation',
              `eventChatFor[${event.docId}]`,
              MessageType.EVENT_INVITATION,
              {
                invitedPlayer: invitedPlayer,
                eventInvitation: true,
                mentionedPlayerDocIds: [
                  invitedPlayer.playerDocId
                ],
                messageDocId: invitedPlayer.messageDocId
              }
            )

          }


          // add tolaria player to the message group for the event
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(this.firestore.collection('messageGroups').doc(`eventChatFor[${event.docId}]`).ref, {
            playerDocIds: firestore.arrayUnion(email.playerDocId)
          })


        }


        // send the email
        const emailData = {
          event: event,
          invitedPlayer,
          organizer: {
            name: `${invitationBy.name.first} ${invitationBy.name.last}`,
            message: emailBody
          }
        }
        await firstValueFrom(this.functions.httpsCallable('email-eventInvitation')(emailData))
          .then((res) => {
            emailErrors.push(res)
            const logText: IEventLog = {
              type: 'invitation',
              timestamp: firestore.Timestamp.now().seconds,
              text: `✔️ Successfully sent email invitation to ${email.email}`,
              metadata: {
                email: email.email,
                playerDocId: email.playerDocId,
                playerUid: email.playerUid,
                invitedByDocId: invitationBy.id,
                invitedByName: `${invitationBy.name.first} ${invitationBy.name.last}`,
              }
            }
            __incrementWriteCounter()
            writeRef = eventRef.collection('log').doc(`log-${uuidv4()}`).ref
            batchWrites[currentBatchIndex].set(writeRef, logText)
          })
          .catch((e) => {
            emailErrors.push(e)
            const logText: IEventLog = {
              type: 'invitation',
              timestamp: firestore.Timestamp.now().seconds,
              text: `❌ Error sending invitation to ${email.email}`,
              metadata: {
                email: email.email,
                playerDocId: email.playerDocId,
                playerUid: email.playerUid,
                invitedByDocId: invitationBy.id,
                invitedByName: `${invitationBy.name.first} ${invitationBy.name.last}`,
                error: JSON.stringify(e)
              }
            }
            __incrementWriteCounter()
            writeRef = eventRef.collection('log').doc(`log-${uuidv4()}`).ref
            batchWrites[currentBatchIndex].set(writeRef, logText)
          })


      }



      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            this.isBusy = false
            console.error(e)
            resolve({
              status: false,
              text: e,
              data: emailErrors
            })
          })
      }

      this.data.invitations$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)
      // return response
      resolve({
        status: true,
        text: emails.length > 1 ? `${emails.length} email invitations sent` : 'Email invitation sent',
        data: emailErrors,
      })



    })

  }

  /**
   * Remove one or more specific invitation from the given event
   *
   * @param invitedPlayers IInvitedPlayer[] - the list of invitations to removed
   * @returns Promise<IPromiseResponse>
   */
  public uninvitePlayer(invitedPlayers: IInvitedPlayer[]): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      this.isBusy = true
      this.busyText = {
        title: 'Removing',
        message: `Please wait, removing ${invitedPlayers.length} ${invitedPlayers.length === 1 ? 'invitation' : 'invitations' }...`
      }

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())

      // get user adding player
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // store the reference to the firestore event document
      const eventRef = this.firestore.collection('events').doc(this.data.docId)

      // perform actions for each invitee
      for (const invitee of invitedPlayers) {

        // check if message should be deleted
        if (invitee.sendMessage && invitee.messageDocId && invitee.messageDocId !== null && invitee.messageDocId !== '') {
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].delete(this.firestore.collection('messages').doc(invitee.messageDocId).ref)
        }

        // delete the sub document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].delete(eventRef.collection('invitations').doc(invitee.email).ref)

        // remove invitation from the event document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedPlayers: firestore.arrayRemove(invitee)
        })


      }

      // log the activity in the event log
      let logText: IEventLog = {
        type: 'invitation',
        timestamp: firestore.Timestamp.now().seconds,
        text: `${invitedPlayers[0].email} removed from the invitation list by user ${userAdding.name.first} ${userAdding.name.last}`,
        metadata: {
          email: invitedPlayers[0].email,
          playerDocId: invitedPlayers[0].playerDocId,
          playerUid: invitedPlayers[0].playerUid,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      if (invitedPlayers.length > 1) {
        logText.text = `${invitedPlayers.length} players removed from the invitaion list by user ${userAdding.name.first} ${userAdding.name.last}`
        logText.metadata = {
          invitees: invitedPlayers,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      __incrementWriteCounter()
      batchWrites[currentBatchIndex].set(eventRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            console.error(e)
            this.isBusy = false
            resolve({
              status: false,
              text: e,
            })
          })
      }


      this.data.invitations$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)
      resolve({
        status: true,
        text: logText.text,
      })


    })
  }

  /**
   * Accept one or more specific invitations
   *
   * @param invitedPlayers IInvitedPlayer[] - the list of invitations to accept
   * @returns Promise<IPromiseResponse>
   */
  public acceptInvitation(invitedPlayers: IInvitedPlayer[]): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      this.isBusy = true
      this.busyText = {
        title: 'Accepting',
        message: `Please wait, accepting ${invitedPlayers.length} ${invitedPlayers.length === 1 ? 'invitation' : 'invitations' }...`
      }

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())

      // get user adding player
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // store the reference to the firestore event document
      const eventRef = this.firestore.collection('events').doc(invitedPlayers[0].eventDocId)

      // perform actions for each invitee
      for (const invitee of invitedPlayers) {

        // accept the invitation on the message if applicable
        if (invitee.sendMessage && invitee.messageDocId && invitee.messageDocId !== null && invitee.messageDocId !== '') {
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(this.firestore.collection('messages').doc(invitee.messageDocId).ref, {
            [`invitedPlayer.accepted`]: true,
            [`invitedPlayer.declined`]: false,
            [`invitedPlayer.answeredAt`]: firestore.Timestamp.now().seconds,
          })
        }

        // update the tournament sub document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.collection('invitations').doc(invitee.email).ref, {
          accepted: true,
          declined: false,
          answeredAt: firestore.Timestamp.now().seconds,
        })

        // remove invitation from the event document before adding the new updated one
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedPlayers: firestore.arrayRemove(invitee)
        })

        // update the properties before updating the event document
        invitee.accepted = true
        invitee.declined = false
        invitee.answeredAt = firestore.Timestamp.now().seconds

        // add the new updated invitation to the event document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedPlayers: firestore.arrayUnion(invitee)
        })

      }


      // log the activity in the event log
      let logText: IEventLog = {
        type: 'invitation',
        timestamp: firestore.Timestamp.now().seconds,
        text: `Invitation for ${invitedPlayers[0].email} accepted by user ${userAdding.name.first} ${userAdding.name.last}`,
        metadata: {
          email: invitedPlayers[0].email,
          playerDocId: invitedPlayers[0].playerDocId,
          playerUid: invitedPlayers[0].playerUid,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      if (invitedPlayers.length > 1) {
        logText.text = `${invitedPlayers.length} invitations accepted by user ${userAdding.name.first} ${userAdding.name.last}`
        logText.metadata = {
          invitees: invitedPlayers,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      __incrementWriteCounter()
      batchWrites[currentBatchIndex].set(eventRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      
      
      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            this.isBusy = false
            console.error(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }


      // enroll the players if applicable
      if (invitedPlayers.filter(i => i.playerDocId !== null).length > 0) {
        const players = invitedPlayers.filter(i => i.playerDocId !== null).map(i => this.playerNameService.getPlayerById(i.playerDocId))
        await this.enrollTolariaPlayers(players, invitedPlayers[0].eventDocId)
      }

      this.data.invitations$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)
      resolve({
        status: true,
        text: logText.text,
      })

    })
  }

  /**
   * Decline one or more specific invitations
   *
   * @param invitedPlayers IInvitedPlayer[] - the list of invitations to decline
   * @returns Promise<IPromiseResponse>
   */
  public declineInvitation(invitedPlayers: IInvitedPlayer[]): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      this.isBusy = true
      this.busyText = {
        title: 'Declining',
        message: `Please wait, declining ${invitedPlayers.length} ${invitedPlayers.length === 1 ? 'invitation' : 'invitations' }...`
      }

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())

      // get user adding player
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // store the reference to the firestore event document
      const eventRef = this.firestore.collection('events').doc(invitedPlayers[0].eventDocId)


      // perform actions for each invitee
      for (const invitee of invitedPlayers) {

        // decline the invitation on the message if applicable
        if (invitee.sendMessage && invitee.messageDocId && invitee.messageDocId !== null && invitee.messageDocId !== '') {
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(this.firestore.collection('messages').doc(invitee.messageDocId).ref, {
            [`invitedPlayer.accepted`]: false,
            [`invitedPlayer.declined`]: true,
            [`invitedPlayer.answeredAt`]: firestore.Timestamp.now().seconds,
          })
        }

        // update the tournament sub document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.collection('invitations').doc(invitee.email).ref, {
          accepted: false,
          declined: true,
          answeredAt: firestore.Timestamp.now().seconds,
        })

        // remove invitation from the event document before adding the new updated one
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedPlayers: firestore.arrayRemove(invitee)
        })

        // update the properties before updating the event document
        invitee.accepted = false
        invitee.declined = true
        invitee.answeredAt = firestore.Timestamp.now().seconds

        // add the new updated invitation to the event document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef.ref, {
          invitedPlayers: firestore.arrayUnion(invitee)
        })


      }

      // log the activity in the event log
      let logText: IEventLog = {
        type: 'invitation',
        timestamp: firestore.Timestamp.now().seconds,
        text: `Invitation for ${invitedPlayers[0].email} declined by user ${userAdding.name.first} ${userAdding.name.last}`,
        metadata: {
          email: invitedPlayers[0].email,
          playerDocId: invitedPlayers[0].playerDocId,
          playerUid: invitedPlayers[0].playerUid,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      if (invitedPlayers.length > 1) {
        logText.text = `${invitedPlayers.length} invitations declined by user ${userAdding.name.first} ${userAdding.name.last}`
        logText.metadata = {
          invitees: invitedPlayers,
          removedByDocId: userAdding.id,
          removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
        }
      }
      __incrementWriteCounter()
      batchWrites[currentBatchIndex].set(eventRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)


      
      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            this.isBusy = false
            console.error(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }

      
      this.data.invitations$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)

      resolve({
        status: true,
        text: logText.text,
      })


    })
  }

  /**
   * Enroll Tolaria players to the event with the given event document id.
   * This will also add the players to the event message group.
   *
   * @param players IPlayerMeta[]
   * @param eventDocId string
   * @returns Promise<IPromiseResponse>
   */
  public enrollTolariaPlayers(players: IPlayerMini[], eventDocId: string): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // create response data
      const eventPlayers: IEventPlayerDetails[] = []

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())



      // get user adding player
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // create event document ref
      const eventDocUri = this.firestore.collection('events').doc(eventDocId)

      for await (const player of players) {

        // add the player document id to the event document
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventDocUri.ref, {
          playerDocIds: firestore.arrayUnion(player.id)
        })

        // log activity
        const logText: IEventLog = {
          type: 'attending',
          timestamp: firestore.Timestamp.now().seconds,
          text: `${player.name.display} added to the event by user ${userAdding.name.first} ${userAdding.name.last}`,
          metadata: {
            playerDocId: player.id,
            playerName: player.name.display,
            addedByDocId: userAdding.id,
            addedByName: `${userAdding.name.first} ${userAdding.name.last}`,
          }
        }
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(eventDocUri.collection('log').doc(`log-${uuidv4()}`).ref, logText)

        // add the player to the message group for the event
        if (player.id.substring(0, 6) !== 'temp__') {
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(this.firestore.collection('messageGroups').doc(`eventChatFor[${eventDocId}]`).ref, {
            playerDocIds: firestore.arrayUnion(player.id)
          })
        }

        // add the player to the event players collection
        const eventPlayer = new EventPlayer(eventDocId, player.uid, player.id, player.name.display).doc
        eventPlayers.push(eventPlayer)
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(this.firestore.collection('events').doc(eventDocId).collection('players').doc(player.id).ref, eventPlayer)

      }


      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }


      // return response
      resolve({
        status: true,
        text: 'All players added to the event',
        data: eventPlayers
      })


    })

  }

  /**
   * Enroll non-Tolaria players to the event with the given event document id.
   *
   * @param playerNames string[]
   * @param eventDocId string
   * @returns Promise<IPromiseResponse>
   */
  public enrollNonTolariaPlayers(playerNames: string[], eventDocId: string): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // get user adding player
      console.log('...getting event organizer')
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())

      // loop through names to create documents
      console.log('...starting player loop')
      for await (const name of playerNames) {

        // create event reference
        const eventPath = this.firestore.collection('events').doc(eventDocId)

        // player variables
        const playerDocId = 'temp__' + name.toLowerCase()
        const playerUid = 'temp__' + name.toLowerCase()

        // add player to event document and add log message
        console.log('...adding player to event and write log')
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventPath.ref, {
          playerDocIds: firestore.arrayUnion(playerDocId),
        })

        // log activity
        const logText: IEventLog = {
          timestamp: firestore.Timestamp.now().seconds,
          type: 'attending',
          text: `${name} added to the event by user ${userAdding.name.first} ${userAdding.name.last}`,
          metadata: {
            playerDocId,
            playerName: name,
            addedByDocId: userAdding.id,
            addedByName: `${userAdding.name.first} ${userAdding.name.last}`,
          }
        }
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(eventPath.collection('log').doc(`log-${uuidv4()}`).ref, logText)

        // add player to the event sub collection of players
        console.log('...adding player to event players collection')
        const playerDoc = new EventPlayer(eventDocId, playerUid, playerDocId, name).doc
        console.log(playerDoc)
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(eventPath.collection('players').doc(playerDocId).ref, playerDoc)

        // app playerDocId to the event messageGroup unless it's a temp player
        if (playerDocId.substring(0, 6) !== 'temp__') {
          console.log('...adding player to message group for event')
          const messageGroupRef = this.firestore.collection('messageGroups').doc('eventChatFor[' + eventDocId + ']').ref
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(messageGroupRef, {
            playerDocIds: firestore.arrayUnion(playerDocId)
          })
        }

      }

      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            resolve({
              status: false,
              text: e,
            })
          })
      }


      resolve({
        status: true,
        text: 'All players added to the event'
      })



    })

  }

  /**
   * Remove a player for the event and the connected message group document.
   *
   * @param players IEventPlayerDetails[]
   * @param eventDocId string
   * @returns Promise<IPromiseResponse>
   */
  public async removePlayer(players: IEventPlayerDetails[], eventDocId: string): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      // create batch for delete and updates
      const batch = this.firestore.firestore.batch()

      // get user unattending the player
      const unattendedBy = this.playerNameService.getPlayerById(this.authService.user.playerId)


      // loop through all players
      for (const player of players) {

        // create log text
        const logText: IEventLog = {
          timestamp: firestore.Timestamp.now().seconds,
          type: 'attending',
          text: `${player.name} removed from the event by ${unattendedBy.name.first} ${unattendedBy.name.last}`,
          metadata: {
            playerDocId: player.playerDocId,
            playerName: player.name,
            addedByDocId: unattendedBy.id,
            addedByName: `${unattendedBy.name.first} ${unattendedBy.name.last}`,
          }
        }

        // check if player has submitted a deck and remove the link
        if (player.deckSubmission.deckListDocId !== null && player.deckSubmission.deckListDocId !== undefined && player.deckSubmission.deckListDocId !== '') {
          let ref: any = null
          // check if version submitted
          if (player.deckSubmission.deckVersionDocId !== null && player.deckSubmission.deckVersionDocId !== undefined && player.deckSubmission.deckVersionDocId !== '') {
            ref = this.firestore.collection('decks').doc(player.deckSubmission.deckListDocId).collection('versions').doc(player.deckSubmission.deckVersionDocId).ref
          }
          else {
            ref = this.firestore.collection('decks').doc(player.deckSubmission.deckListDocId).ref
          }
          batch.update(ref, {
            eventDocIds: firestore.arrayRemove(eventDocId)
          })
        }


        // delete the events > players > doc
        batch.delete(this.firestore.collection('events').doc(eventDocId).collection('players').doc(player.playerDocId).ref)


        // remove player from messageGroups > event message group
        if (!player.playerDocId.includes('temp__')) {
          batch.update(this.firestore.collection('messageGroups').doc(`eventChatFor[${eventDocId}]`).ref, {
            playerDocIds: firestore.arrayRemove(player.playerDocId)
          })
        }


        // remove player from event document
        batch.update(this.firestore.collection('events').doc(eventDocId).ref, {
          playerDocIds: firestore.arrayRemove(player.playerDocId),
        })


        // log the event
        batch.set(this.firestore.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)

      }





      // commit the batch
      batch.commit()
        .then(() => resolve({
          status: true,
          text: `${players.length} ${players.length > 1 ? 'players' : 'player'} from the event by ${unattendedBy.name.first} ${unattendedBy.name.last}`
        }))
        .catch((e) => resolve({
          status: false,
          text: e
        }))



    })



  }

  /**
   * Add a single player to the tournament waiting list.
   * Optionally set the isTemp param to pass a NAME as the playerDocId
   * and this will be converted into a temp player document id when added to
   * the waiting list.
   * 
   * @param playerDocIds array of strings
   * @param eventDocId string
   * @param isTemp boolean optional
   * @returns IPromiseResponse
   */
  public addPlayerToWaitingList(playerDocIds: string[], eventDocId: string, isTemp: boolean = false): Promise<IPromiseResponse> {

    return new Promise(async (resolve) => {

      // batch processing variables
      const batchWrites: firebase.default.firestore.WriteBatch[] = []
      let currentWriteIndex = 0
      let currentBatchIndex = 0

      // get user adding player
      console.log('...getting event organizer')
      const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

      // local method to icrement batch variables
      const __incrementWriteCounter = () => {
        console.log('...incremeting write index')
        if ((currentWriteIndex % 500) === 0 && (currentWriteIndex / 500) === (currentBatchIndex + 1)) {
          console.log('...incremeting batch index')
          batchWrites.push(this.firestore.firestore.batch())
          currentBatchIndex++
        }
        currentWriteIndex++
      }

      // create first batch
      console.log('...creating the first batch for writes to firestore')
      batchWrites.push(this.firestore.firestore.batch())

      // loop through names to create documents
      console.log('...starting player loop')
      for await (const id of playerDocIds) {

        // get name
        const name = isTemp
          ? id
          : this.playerNameService.getPlayerById(id).name.display
        const playerId = isTemp
          ? 'temp__' + id.toLowerCase()
          : id

        // add player to event document and add log message
        console.log('...adding player to event and write log')
        const eventRef = this.firestore.collection('events').doc(eventDocId).ref
        // __incrementWriteCounter()
        // batchWrites[currentBatchIndex].update(eventRef, {
        //   playerDocIds: firestore.arrayUnion(playerId),
        // })

        // log activity
        const logText: IEventLog = {
          timestamp: firestore.Timestamp.now().seconds,
          type: 'attending',
          text: `${name} added to the <span class="text-warning text-bold">waiting list</span> by user ${userAdding.name.first} ${userAdding.name.last}`,
          metadata: {
            playerDocId: playerId,
            playerName: name,
            addedByDocId: userAdding.id,
            addedByName: `${userAdding.name.first} ${userAdding.name.last}`,
          }
        }
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].set(this.firestore.collection('events').doc(eventDocId).collection('log').doc(`log-${uuidv4()}`).ref, logText)

        // add player to the event sub collection of players
        console.log('...adding player to event players collection')
        // create waiting list entry
        const waiting: IWaitListPlayer = {
          added: firestore.Timestamp.now().seconds,
          playerDocId: isTemp ? `temp__${id.toLowerCase().replace(/ /g, '')}` : id,
          name: name,
        }
        __incrementWriteCounter()
        batchWrites[currentBatchIndex].update(eventRef, {
          waitingList: firestore.arrayUnion(waiting),
        })

        // app playerDocId to the event messageGroup unless it's a temp player
        if (!isTemp) {
          console.log('...adding player to message group for the tournament event')
          const messageGroupRef = this.firestore.collection('messageGroups').doc('eventChatFor[' + eventDocId + ']').ref
          __incrementWriteCounter()
          batchWrites[currentBatchIndex].update(messageGroupRef, {
            playerDocIds: firestore.arrayUnion(id)
          })
        }

      }

      // perform writes
      console.log('...writing event player documetns as well as event updates to firebase')
      for await (const [index, batch] of batchWrites.entries()) {
        batch
          .commit()
          .then(() => console.log(`...writing of batch #${index + 1} successful`))
          .catch((e) => {
            console.log(e)
            this.toast.show('Not able to add the player to the waiting list, please try again', { classname: 'error-toast', delay: 3000 })
            resolve({
              status: false,
              text: e,
            })
          })
      }


      this.toast.show('Players added to the waiting list', { classname: 'success-toast' })
      resolve({
        status: true,
        text: 'All players added to the waiting list'
      })

    })

  }

  /**
   * Removes a specific player from the waiting list.
   * 
   * @param player IWaitListPlayer
   */
  public removePlayerFromWaitingList(player: IWaitListPlayer): void {

    // get user adding player
    console.log('...getting event organizer')
    const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

    // create event document reference
    const eventDocRef = this.firestore.collection('events').doc(this.data.docId)

    // create batch for writes and updates
    const batch = this.firestore.firestore.batch()

    // remove unwanted property or matching will fail
    delete player.selected

    // remove player from waiting list
    batch.update(eventDocRef.ref, {
      waitingList: firestore.arrayRemove(player)
    })

    // log activity
    const logText: IEventLog = {
      timestamp: firestore.Timestamp.now().seconds,
      type: 'attending',
      text: `${player.name} removed from the <span class="text-warning text-bold">waiting list</span> by user ${userAdding.name.first} ${userAdding.name.last}`,
      metadata: {
        playerDocId: player.playerDocId,
        playerName: player.name,
        removedByDocId: userAdding.id,
        removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
      }
    }
    batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)


    // perform writes
    console.log('...writing log and updates to firebase')
    batch
      .commit()
      .then(() => this.toast.show('Player removed from the waiting list', { classname: 'success-toast' }))
      .catch((e) => {
        console.log(e)
        this.toast.show('Not able to remove the player from the waiting list, please try again', { classname: 'error-toast', delay: 3000 })
      })

  }

  /**
   * Remove a list of players from the waiting list.
   * 
   * @param players IWaitLisPlayer[] - array of the players to ber removed
   */
  public removePlayersFromWaitingList(players: IWaitListPlayer[]): void {
    // get user adding player
    console.log('...getting event organizer')
    const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)

    // create event document reference
    const eventDocRef = this.firestore.collection('events').doc(this.data.docId)

    // create batch for writes and updates
    const batch = this.firestore.firestore.batch()

    // remove players from waiting list
    for (const player of players) {
      // remove unwanted property or matching will fail
      delete player.selected
      batch.update(eventDocRef.ref, {
        waitingList: firestore.arrayRemove(player)
      })
    }

    // log activity
    const logText: IEventLog = {
      timestamp: firestore.Timestamp.now().seconds,
      type: 'attending',
      text: `${players.length} ${players.length > 1 ? 'players' : 'player'} removed from the <span class="text-warning text-bold">waiting list</span> by user ${userAdding.name.first} ${userAdding.name.last}`,
      metadata: {
        playerDocIds: players.map(i => i.playerDocId),
        playerNames: players.map(i => i.name),
        removedByDocId: userAdding.id,
        removedByName: `${userAdding.name.first} ${userAdding.name.last}`,
      }
    }
    batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)


    // perform writes
    console.log('...writing log and updates to firebase')
    batch
      .commit()
      .then(() => this.toast.show('All selected players removed from the waiting list', { classname: 'success-toast' }))
      .catch((e) => {
        console.log(e)
        this.toast.show('Not able to remove the selected players from the waiting list, please try again', { classname: 'error-toast', delay: 3000 })
      })
  }

  /**
   * This method will unattend the current user from the event in question.
   *
   * @param eventDocId string
   * @returns Promise<boolean>
   */
  public attendMe(eventDocId: string): Promise<boolean> {

    return new Promise(async (resolve) => {

      // get users meta data
      const playerMeta = this.playerNameService.getPlayerById(this.authService.user.playerId)
      // const playerMeta = await this.playerMetaService.getPlayerMeta(this.authService.user.playerId)
      if (playerMeta === null) { resolve(false) }

      // call the enroll tolaria player method with the players meta
      this.enrollTolariaPlayers([playerMeta], eventDocId)
        .then((res) => res.status ? resolve(true) : resolve(false))
        .catch((e) => resolve(false))


    })



  }

  /**
   * This method will unattend the current user from the event in question.
   *
   * @param eventDocId string
   * @returns Promise<boolean>
   */
  public unattendMe(eventDocId: string): Promise<boolean> {

    return new Promise(async (resolve) => {

      // get the users event player document
      const player = await firstValueFrom(this.firestore.collection('events').doc(eventDocId).collection('players').doc<IEventPlayerDetails>(this.authService.user.playerId).get())

      // check if player was fetched
      if (!player.exists) { resolve(false) }

      // call the remove player method with the event player document
      this.removePlayer([player.data()], eventDocId)
        .then((res) => res.status ? resolve(true) : resolve(false))
        .catch((e) => resolve(false))


    })



  }

  /**
   * Update the current users deck submission in the current tournament/event
   * Adds the event document reference to the deck or deck version passed as new deck sumbitted
   * Removes the event document reference to the deck or deck version passed as the previous submitted deck
   *
   * @param eventDocId string - event document id
   * @param deckSubmission IDeckSubmission - the new deck submission config
   * @param deckSubmissionBefore IDeckSubmission - the previous deck submission config
   * @returns IPromiseResponse - the result of the updates
   */
  public updateMyDeckSubmission(eventDocId: string, deckSubmission: IDeckSubmission, deckSubmissionBefore: IDeckSubmission): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // create batch for updates
      const bacth = this.firestore.firestore.batch()

      // update deck sumission
      const eventPlayerRef = this.firestore.collection('events').doc(eventDocId).collection('players').doc(this.authService.user.playerId).ref
      bacth.update(eventPlayerRef, {
        deckSubmission: deckSubmission
      })

      // perform updates on the previously submitted deck list
      if (deckSubmissionBefore.deckListDocId !== null && deckSubmissionBefore.deckListDocId !== undefined && deckSubmissionBefore.deckListDocId !== '') {

        // define document ref
        let docBefore = this.firestore.collection('decks').doc(deckSubmissionBefore.deckListDocId)

        // check if previous submitted deck was a version and update document ref
        if (deckSubmissionBefore.deckVersionDocId !== null && deckSubmissionBefore.deckVersionDocId !== undefined && deckSubmissionBefore.deckVersionDocId !== '') {
          docBefore = docBefore.collection('versions').doc(deckSubmissionBefore.deckVersionDocId)
        }

        // update deck/version
        bacth.update(docBefore.ref, {
          eventDocIds: firestore.arrayRemove(eventDocId)
        })

      }

      // perform updates on the newly submitted deck list
      if (deckSubmission.deckListDocId !== null && deckSubmission.deckListDocId !== undefined && deckSubmission.deckListDocId !== '') {

        // define document ref
        let doc = this.firestore.collection('decks').doc(deckSubmission.deckListDocId)

        // check if previous submitted deck was a version and update document ref
        if (deckSubmission.deckVersionDocId !== null && deckSubmission.deckVersionDocId !== undefined && deckSubmission.deckVersionDocId !== '') {
          doc = doc.collection('versions').doc(deckSubmission.deckVersionDocId)
        }

        // update deck/version
        bacth.update(doc.ref, {
          eventDocIds: firestore.arrayUnion(eventDocId)
        })

      }

      // commit batch
      bacth
        .commit()
        .then(() => resolve({ status: true, text: 'Decksubmission successfully updated.' }))
        .catch((e) => resolve({ status: false, text: e }))

    })
  }

  /**
   * Adds the current player to the waiting list
   */
  public addMeToWaitList() {
    this.addPlayerToWaitingList([this.playerNameService.currentPlayersMini.id], this.data.docId)
  }

  /**
   * Removes the current player from the waiting list
   */
  public removeMeFromWaitList() {
    const waitListPlayer = this.data.event$.getValue().waitingList.find(i => i.playerDocId === this.playerNameService.currentPlayersMini.id)
    waitListPlayer !== undefined
      ? this.removePlayerFromWaitingList(waitListPlayer)
      : null
  }

  public createNewTeam() {
    this.modalService
      .open(TournamentManagementNewTeamComponent, {
        animation: false,
        centered: true,
      })
      .result
      .then(
        (teamData: IEventTeamMeta) => this.addTeam(this.data.id, teamData),
        () => { }
      )
  }

  /**
   * Add a new team to a swiss teams tournament
   * 
   * @param eventDocId string - event document id
   * @param team IEventTeam - the new team to be added
   * @returns Promise<boolean> - success true or false
   */
  public addTeam(eventDocId: string, team: IEventTeam): Promise<IPromiseResponse> {
    return new Promise(async (resolve) => {

      // get player performing the action and player being dropped
      const currentPlayer = this.playerNameService.currentPlayersMini

      // get all teams
      const teams = this.data.teams$.getValue()

      // check if name has been used
      if (teams.find(i => i.name === team.name)) {
        team.name = `${team.name} (1)`
      }

      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // create document references
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)
      const teamDocRef = eventDocRef.collection('teams').doc(team.id).ref

      // add the new team document id to the tournament document
      batch.update(eventDocRef.ref, {
        teamIds: firestore.arrayUnion(team.id)
      })

      // add event log entry
      const logText: IEventLog = {
        type: 'team',
        timestamp: firestore.Timestamp.now().seconds,
        text: `${team.name} added by ${currentPlayer.name.display}.`,
        metadata: {
          updatedByPlayerId: currentPlayer.id,
          updatedByPlayerUid: currentPlayer.uid,
          teamName: team.name,
          teamId: team.id,
        }
      }
      // add write of log document
      batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // remove unwanted properties
      const cleanData = ModelProcess.clean.teamData(team)

      // add the new team document to the teams sub collection
      batch.set(teamDocRef, cleanData)

      batch.commit()
        .then(() => {
          const teamData = this.data.mapTeamData(team, this.data.playerList)
          resolve({
            status: true,
            text: 'Success',
            data: teamData
          })
        })
        .catch((error) => {
          console.error(error)
          resolve({
            status: false,
            text: error,
          })
        })

    })
  }

  /**
   * Edit a specific team. Upon save, the team will be updated
   * as well as all affected players. 
   * Removed players will be unassigned and removed from any previous seat.
   * New players will be assigned and seated if set.
   * 
   * @param team IEventTeamMeta - the team to edit
   */
  public async editTeam(team: IEventTeamMeta) {

    const modalRef = this.modalService.open(TournamentManagementTeamConfigComponent, {
      centered: true,
      animation: false,
      size: 'lg',
    })

    modalRef.componentInstance.team = team
    const updatedTeam: IEventTeamMeta = await modalRef.result.then((updatedTeam: IEventTeamMeta) => updatedTeam, () => null)

    console.log(updatedTeam)

    if (updatedTeam === null) {
      return
    }

    this.isBusy = true
    this.busyText = {
      title: 'Saving',
      message: 'Team configuration, assigned players, and seating is being processed, please wait...'
    }

    const playersRemoved = team.playerDocIds.filter(i => !updatedTeam.playerDocIds.includes(i))
    const playersAdded = updatedTeam.playerDocIds.filter(i => !team.playerDocIds.includes(i))
    console.log({
      updatedTeam,
      playersAdded,
      playersRemoved
    })

    // get player performing the action and player being dropped
    const currentPlayer = this.playerNameService.currentPlayersMini

    // create a batch for writing
    const batch = this.firestore.firestore.batch()

    // create document references
    const eventDocRef = this.firestore.collection('events').doc(this.data.docId)
    const teamDocRef = eventDocRef.collection('teams').doc(team.id).ref

    // add event log entry
    const logText: IEventLog = {
      type: 'team',
      timestamp: firestore.Timestamp.now().seconds,
      text: `${team.name} modified by ${currentPlayer.name.display}.`,
      metadata: {
        updatedByPlayerId: currentPlayer.id,
        updatedByPlayerUid: currentPlayer.uid,
        nameChanged: team.name !== updatedTeam.name,
        playersChanged: playersRemoved.length > 0 || playersAdded.length > 0,
        seatingsChanged: JSON.stringify(team.players) !== JSON.stringify(updatedTeam.players),
      }
    }
    if (logText.metadata.nameChanged) {
      logText.metadata['newName'] = updatedTeam.name
      logText.metadata['previousName'] = team.name
    }
    if (logText.metadata.playersChanged) {
      logText.metadata['playersAdded'] = playersAdded.join(', ')
      logText.metadata['playersRemoved'] = playersRemoved.join(', ')
    }

    // add write of log document
    batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

    // update removed players
    for (const id of playersRemoved) {
      batch.update(eventDocRef.collection('players').doc(id).ref, {
        teamId: null,
        teamSeat: null,
      })
    }

    // update the currently assigned players
    for (const id of updatedTeam.playerDocIds) {
      batch.update(eventDocRef.collection('players').doc(id).ref, {
        teamId: updatedTeam.id,
        teamSeat: updatedTeam.player.a === id
          ? 'A'
          : updatedTeam.player.b === id
            ? 'B'
            : updatedTeam.player.c === id
              ? 'C'
              : null,
      })
    }

    // remove unwanted properties
    const cleanData = ModelProcess.clean.teamData(updatedTeam)

    // update the teams sub collection with the new document
    batch.set(teamDocRef, cleanData)

    batch.commit()
      .then(() => { })
      .catch((error) => console.error(error))

    this.data.players$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)

  }

  /**
   * Drop or Undrop the team in question.
   * 
   * @param eventDocId string - tournament document id
   * @param team IEventTeamMeta - the team to be dropped
   * @param dropstate boolean - drop or undrop the event,
   * @returns Promise<boolean> - true if team successfully dropped
   */
  public async updateTeamDropState(eventDocId: string, team: IEventTeamMeta, dropstate: boolean): Promise<boolean> {

    return new Promise((resolve) => {

      // get player performing the action and player being dropped
      const currentPlayer = this.playerNameService.currentPlayersMini

      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // create document references
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)
      const teamDocRef = eventDocRef.collection('teams').doc(team.id).ref

      // add event log entry
      const logText: IEventLog = {
        type: 'team',
        timestamp: firestore.Timestamp.now().seconds,
        text: `${team.name} ${dropstate ? 'dropped' : 'undropped'} by ${currentPlayer.name.display}. All assigned players now unassigned.`,
        metadata: {
          updatedByPlayerId: currentPlayer.id,
          updatedByPlayerUid: currentPlayer.uid,
          teamName: team.name,
          teamId: team.id,
        }
      }
      // add write of log document
      batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // update the sub collection team document
      batch.update(teamDocRef, {
        dropped: dropstate
      })      

      // commit the batch
      batch.commit()
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
          resolve(false)
        })

    })

  }

  /**
   * Delete a team and unassign all assigned players.
   * 
   * @param eventDocId string - tournament document id
   * @param team IEventTeamMeta - the team to be deleted
   * @returns Promise<boolean> - true if team successfully deleted
   */
  public async deleteTeam(eventDocId: string, team: IEventTeamMeta): Promise<boolean> {

    // prompt for deletion
    const confirmation = await this.confirm
      .open({
        type: 'danger',
        title: 'Delete Team',
        message: `Are you sure you want to delete the team <b>${team.name}</b>?<br>All players will be unassigned from the team and the team will be forever deleted!`,
        buttons: [
          {
            type: 'dismiss',
            text: 'Cancel',
            value: 'dismiss',
          },
          {
            type: 'close',
            text: 'Delete',
            value: 'delete',
          },
        ]
      })
      .then((value) => value)
      .catch((error) => error)

    return new Promise((resolve) => {

      // check response
      if (confirmation !== 'delete') {
        resolve(false)
      }

      // get player performing the action and player being dropped
      const currentPlayer = this.playerNameService.currentPlayersMini

      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // create document references
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)
      const teamDocRef = eventDocRef.collection('teams').doc(team.id).ref

      // delete the team document
      batch.delete(teamDocRef)

      // remove the team from the event document
      batch.update(eventDocRef.ref, {
        teamIds: firestore.arrayRemove(team.id)
      })

      // add event log entry
      const logText: IEventLog = {
        type: 'team',
        timestamp: firestore.Timestamp.now().seconds,
        text: `${team.name} deleted by ${currentPlayer.name.display}. All assigned players now unassigned.`,
        metadata: {
          updatedByPlayerId: currentPlayer.id,
          updatedByPlayerUid: currentPlayer.uid,
          teamName: team.name,
          teamId: team.id,
        }
      }
      // add write of log document
      batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // remove all team references for the assigned players
      for (const player of team.playerDocIds) {
        batch.update(eventDocRef.collection('players').doc(player).ref, {
          teamId: null,
          teamSeat: null,
        })
      }

      // commit the batch
      batch.commit()
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          console.error(error)
          resolve(false)
        })

    })


  }


  /**
   * Open the team configuration dialog where you can assign
   * the player to a team and select where to be seated.
   * When closing the dialog, pass { team: IEventTeam, eventDocId: string }
   * as this wll be needed to add the player
   * 
   * @param player IEventPlayerDetails - The tournament player document
   */
  public openPlayerTeamConfig(player: IEventPlayerDetails) {

    this.isBusy = true
    this.busyText = {
      title: 'Team',
      message: 'Assigning player to team, please wait...'
    }

    const modal = this.modalService.open(TournamentManagementPlayerTeamConfigComponent, {
      centered: true,
      animation: false,
    })

    modal.componentInstance.player = player

    modal.result.then(
      (data: { team: IEventTeamMeta, eventDocId: string }) => {
        // closed
        if (data && data.team && data.eventDocId) {
          this.addPlayerToTeam(data.eventDocId, data.team, player)
          this.data.players$.pipe(skip(1), take(1)).subscribe(i => this.isBusy = false)
        }
      },
      () => {
        // dismissed
        this.isBusy = false
      }
    )
  }

  /**
   * Add a player to tournament team
   * 
   * @param eventDocId string - The tournament document id
   * @param team IEventTeam - The team document to be SET in backend
   * @param player IEventPlayerDetails - The player that has been added to the team
   * @returns Promise<IPromiseResponse>
   */
  public addPlayerToTeam(eventDocId: string, team: IEventTeamMeta, player: IEventPlayerDetails) {

    return new Promise((resolve) => {

      // create batch for updates and writes
      const batch = this.firestore.firestore.batch()

      // create reference to event document
      const eventDocRef = this.firestore.collection('events').doc(eventDocId)

      // get player performing the action and player being dropped
      const currentPlayer = this.playerNameService.currentPlayersMini

      // selected seat
      const seat = team.player.a === player.playerDocId
        ? 'A'
        : team.player.b === player.playerDocId
          ? 'B'
          : team.player.c === player.playerDocId
            ? 'C'
            : 'NOT SEATED'

      // add event log entry
      const logText: IEventLog = {
        type: 'attending',
        timestamp: firestore.Timestamp.now().seconds,
        text: `${player.name} added to team ${team.name} by ${currentPlayer.name.display}. Seated at seat ${seat}`,
        metadata: {
          playerDocId: player.playerDocId,
          playerUid: player.playerUid,
          updatedByPlayerId: currentPlayer.id,
          updatedByPlayerUid: currentPlayer.uid,
          seatedAt: seat.toLowerCase(),
          teamName: team.name,
          teamId: team.id,
        }
      }
      // add write of log document
      batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)

      // update the player document
      batch.update(eventDocRef.collection('players').doc(player.playerDocId).ref, {
        teamId: team.id,
        teamSeat: seat,
      })

      // remove unwanted properties
      const cleanData = ModelProcess.clean.teamData(team)

      // update the team document
      batch.set(eventDocRef.collection('teams').doc(team.id).ref, cleanData)

      // perform updates and writes
      batch.commit()
        .then(() => resolve({ status: true, text: `Player added to team ${team.name} seated at ${seat}` }))
        .catch((e) => resolve({ status: false, text: JSON.stringify(e) }))

    })


  }


  public dropAndMore(player: IEventPlayerMeta) {

    const userAdding = this.playerNameService.getPlayerById(this.authService.user.playerId)
    
    const modalRef = this.modalService.open(TournamentDropActionsComponent, {
      centered: false,
    })
    modalRef.componentInstance.playerDropping = player.eventPlayer

    modalRef.result.then(
      async (result: { exchangePlayer: boolean, playerEntering: IPlayerMini, eventType: "batch" | "swiss" | "group" | "bracket" | "round-robin" }) => {
        switch (result.eventType) {

          case 'group':
            let text = ''
            if (result.exchangePlayer) {
              // set player drop state
              await this.setPlayerDroppedState(true, this.data.docId, [player.eventPlayer.playerDocId])
              // enroll the player entering instead of the dropped one
              if (result.playerEntering.id.includes('temp')) {
                await this.enrollNonTolariaPlayers([result.playerEntering.name.display], this.data.docId)
              }
              else {
                await this.enrollTolariaPlayers([result.playerEntering], this.data.docId)
              }
              // exchange players for all match documents
              const matchDocs = this.data.matches$.getValue().filter(i => i.playerDocIds.includes(player.eventPlayer.playerDocId))
              await this.matches.exchangePlayer(player.eventPlayer, result.playerEntering, matchDocs)
              text = `${player.eventPlayer.name} exchanged for player ${result.playerEntering.name.display}. ${matchDocs.length} matches was updated with the new player entering the event.`
            }
            else {
              await this.setPlayerDroppedState(true, this.data.docId, [player.eventPlayer.playerDocId])
              const matchDocIds = this.data.matches$.getValue().filter(i => i.playerDocIds.includes(player.eventPlayer.playerDocId)).map(i => i.docId)
              await this.matches.inactivateMatches(matchDocIds)
              text = `${matchDocIds.length} matches was inactivated.`
            }

            // log activity
            const logText: IEventLog = {
              timestamp: firestore.Timestamp.now().seconds + 1,
              type: 'attending',
              text,
              metadata: {}
            }
            await this.firestore.collection('events').doc(this.data.docId).collection('log').doc(`log-${uuidv4()}`).set(logText)

            break

          default:
            await this.setPlayerDroppedState(true, this.data.docId, [player.eventPlayer.playerDocId])

        }
      },
    )
  }

}
