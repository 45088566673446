import { ToastService } from './toast.service';
import { HttpClient } from '@angular/common/http';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { take, map, switchMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/auth.service';
import { IBooster, IBoosterCard, IDraft, IDraftSet, IMessageGroupDocument, IPick, IPromiseResponse } from 'tolaria-cloud-functions/src/_interfaces';
import * as firestore from 'firebase/firestore'
import { GlobalsService } from './globals.service';
import { IOnlineUser } from '../components/events/match-room/match-room.component';

export enum DraftStatusChange {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS'
}

@Injectable({
  providedIn: 'root'
})
export class DraftService {

  public statuses = [
    'Being configured',                     // 0
    'Waiting for players to attend',        // 1
    'Waiting for players to be seated',     // 2
    'Preparing all the products',           // 3
    'Drafting',                             // 4
    'Finished'                              // 5
  ];
  private drafts$: Observable<IDraft[]>;
  private setList$: Observable<IDraftSet[]>;
  public filter: IDraftListFilters = {
    nameString: '',
    status: [
      {
        statusCode: 0,
        text: 'Open for registration',
        selected: true
      },
      {
        statusCode: 2,
        text: 'Running',
        selected: true
      },
      {
        statusCode: 3,
        text: 'Finished',
        selected: true
      }
    ]
  }
  constructor(
    private afs: AngularFirestore,
    private auth: AuthService,
    private storage: AngularFireStorage,
    private http: HttpClient,
    private toastService: ToastService,
    private globals: GlobalsService,
  ) {

    const draftsCollection = this.afs.collection<IDraft>('drafts');
    this.drafts$ = draftsCollection.valueChanges();

    const draftSetsCollection = this.afs.collection<IDraftSet>('draftSets', ref => ref.orderBy('releaseDate', 'asc'));
    this.setList$ = draftSetsCollection.valueChanges();

  }
  public clearFilters() {
    this.filter.nameString = '';
    this.filter.status.forEach((s) => s.selected = true);
  }
  private setListOutput() {
    const ref = this.storage.ref('cards/SetList.json');
    ref.getDownloadURL().pipe(
      switchMap((url: string) => {
        return this.http.get(url);
      }),
      take(1)
    ).subscribe((data: IMtgJsonFile) => {
      const sets: ISetList[] = data.data;
      sets.sort((a, b) => a.releaseDate > b.releaseDate ? 1 : -1);
      // const setList: any = data.data.filter(a => a.type !== 'expansion' || a.type !== 'core')
      const setList: any = data.data
        .map(set => {
          return {
            name: set.name,
            code: set.code,
            keyruneCode: set.keyruneCode.toLowerCase(),
            block: set.block,
            releaseDate: set.releaseDate,
            totalSetSize: set.totalSetSize,
            isCore: set.type === 'core',
            isExpansion: set.type === 'expansion',
            isCustom: false,
            storageUrl: 'set-files/' + set.code + '.json',
            downloadUrl: '',
          };
        });
      console.log(JSON.stringify(setList));
    });
  }
  public getSetList(): Observable<IDraftSet[]> {
    return this.setList$.pipe(
      map((sets) => {
        sets.forEach((set) => {
          set.selected = false
        })

        return sets;
      })
    );
  }
  public getDrafts(): Observable<IDraft[]> {
    return this.drafts$;
  }
  public getDraftDocObserver(docId: string): Observable<IDraft> {
    return this.afs.collection('drafts').doc<IDraft>(docId).valueChanges();
  }
  public getDraftByDocId(docId: string): Promise<IDraft> {
    return new Promise((resolve, reject) => {
      this.drafts$.pipe(
        map((drafts: IDraft[]) => {
          const draft = drafts.find(d => d.docId === docId);
          if (draft !== undefined) {
            return draft;
          }
        }),
        take(1)
      ).subscribe((draft: IDraft) => {
        if (draft !== undefined) {
          resolve(draft);
        }
        else {
          console.error(`DraftService: Can't find document with id ${docId}`);
          reject(`DraftService: Can't find document with id ${docId}`);
        }
      });
    });
  }
  public getBoostersForDraft(docId: string): Observable<IBooster[]> {
    return this.afs.collection('drafts').doc(docId).collection<IBooster>('boosters').valueChanges();
  }
  public getCardsForDraft(docId: string): Observable<IBoosterCardDocument[]> {
    return this.afs.collection('drafts').doc(docId).collection<IBoosterCardDocument>('cards').valueChanges();
  }
  public getPicksForDraft(docId: string): Observable<IPick[]> {
    return this.afs.collection('drafts').doc(docId).collection<IPick>('picks').valueChanges();
  }
  public newDraft(): IDraft {
    const draft: IDraft = {
      docId: uuidv4(),
      createdByUid: this.auth.user.uid,
      createdByPlayerDocId: this.auth.user.playerId,
      createdTimestamp: new Date().getTime(),
      name: '',
      datestamp: null,
      statusCode: 0,
      statusText: 'Being configured',
      playerDocIds: [],
      seatedPlayers: [],
      playerCap: 8, // should be configurable in the end
      isPublic: true,
      isTimed: false,
      isLive: false,
      isWebcam: false,
      isBooster: false,
      isRochester: false,
      isRotisserie: false,
      includeBasicLands: true,
      includeAnteCards: true,
      sets: [],
      numberOfPicks: 45,
      packs: {
        a: null,
        b: null,
        c: null,
      },
      activePack: null,
      activeBoosterDocId: null,
      activePlayerDocId: null
    };

    return draft;
  }
  public saveDraftDocument(draft: IDraft, editing: boolean): Promise<IPromiseResponse> {
    return new Promise((resolve) => {
      this.afs.collection('drafts').doc(draft.docId)
        .set(draft)
        .then(async () => {
          // if new draft, create the needed message group doc
          if (!editing) {
            console.log('new draft, create message group')
            await this.createDraftMessageGroup(draft.docId, draft.name);
          }
          console.log(`successfully saved draft document with id: ${draft.docId}`);
          this.toastService.show('Draft saved', { classname: 'success-toast', delay: 2000 });
          resolve({
            status: true,
            text: `successfully saved draft document with id: ${draft.docId}`
          });
        })
        .catch((err) => {
          console.error(err);
          this.toastService.show('Something went wrong, please try again...', { classname: 'error-toast', delay: 8000 });
          resolve({
            status: false,
            text: err
          });
        });
    });
  }
  private createDraftMessageGroup(draftDocId: string, draftName: string) {
    return new Promise((resolve) => {
      const timestamp = firestore.Timestamp.now().seconds;
      const messageGroupDoc: IMessageGroupDocument = {
        docId: `draftChat[${draftDocId}]`,
        createdByUid: this.auth.user.uid,
        createdDate: timestamp,
        latestMessage: timestamp,
        latestMessagePreview: '',
        playerDocIds: [
          this.auth.user.playerId
        ],
        isSingle: false,
        name: draftName
      };

      this.afs.collection('messageGroups')
        .doc(messageGroupDoc.docId)
        .set(messageGroupDoc)
        .then(() => {
          console.log('draft message group created', messageGroupDoc.docId);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        });
    });
  }
  public deleteDraftDocument(draftDocId: string): void {
    console.log(`[draftService] => Delete draft document ${draftDocId}`);
    // delete draft document
    this.afs.collection('drafts').doc(draftDocId)
      .delete()
      .then(() => console.log('Delete successfull'))
      .catch((err) => console.error(err));
  }
  public attendDraft(draftDocId: string): void {
    this.afs.collection('drafts').doc(draftDocId)
      .update({
        playerDocIds: firestore.arrayUnion(this.auth.user.playerId)
      })
      .then(() => console.log('successfully attended'))
      .catch((err) => console.error(err));
  }
  public unattendDraft(draftDocId: string): void {
    this.afs.collection('drafts').doc(draftDocId)
      .update({
        playerDocIds: firestore.arrayRemove(this.auth.user.playerId)
      })
      .then(() => console.log('successfully unattended'))
      .catch((err) => console.error(err));
  }
  public unattendPlayerFromDraft(draftDocId: string, playerDocId: string): Promise<boolean> {

    return new Promise((resolve, reject) => {

      this.afs.collection('drafts').doc(draftDocId)
        .update({
          playerDocIds: firestore.arrayRemove(playerDocId)
        })
        .then(() => {
          console.log('Player removed from the player list');
          resolve(true);
        })
        .catch((err) => {
          console.error(err);
          reject();
        });

    });
  }
  public async updateDraftStatus(draftDocId: string, currentStatusCode: number, change: DraftStatusChange): Promise<boolean> {

    let newStatusCode = null;

    switch (change) {
      case DraftStatusChange.NEXT:
        newStatusCode = currentStatusCode + 1;
        break;
      case DraftStatusChange.PREVIOUS:
        newStatusCode = currentStatusCode - 1;
        break;
    }

    // if less than 0, set to 0
    if (newStatusCode < 0) { newStatusCode = 0; }

    // update status
    const result = await this.afs.collection('drafts').doc(draftDocId)
      .update({
        statusCode: newStatusCode,
        statusText: this.statuses[newStatusCode],
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    return result;

  }
  public async startDraft(draftDocument: IDraft, activeUsers: IOnlineUser[]) {
    if (draftDocument.isBooster) {
      console.log('Starting BOOSTER draft');
      this.startDraft_BoosterDraft(draftDocument, activeUsers);
    }
    if (draftDocument.isRochester) {
      console.log('Starting ROCHESTER draft');
      this.startDraft_RochesterDraft(draftDocument, activeUsers);
    }
    if (draftDocument.isRotisserie) {
      console.log('Starting ROTISSERIE draft');
      this.startDraft_RotisserieDraft(draftDocument, activeUsers);
    }
  }
  private async startDraft_BoosterDraft(draftDocument: IDraft, activeUsers: IOnlineUser[]) {
    // set app as busy
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;

    // update draft status to show players that products are being prepared
    await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);
    // locally update the statusCode as well
    draftDocument.statusCode++;

    // seat players
    console.log('Seat active players');
    draftDocument = await this.seatPlayersForDraft(draftDocument, activeUsers);

    // generate boosters
    console.log('Generating boosters for all active players');
    this.globals.isBusy.message = 'Generating boosters for all active players';
    const boosters: IBoosterPackList = await this.generateBoosters(draftDocument);

    // assign boosters to players
    console.log('Boosters generated, assign boosters to players');
    this.globals.isBusy.message = 'Assigning boosters to players';
    const assignedBoosters = await this.assignBoostersToPlayers(draftDocument, boosters);

    // update status
    this.globals.isBusy.message = 'Updating draft status';
    const statusUpdated = await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);

    // start
    this.globals.isBusy.message = 'Starting the draft';
    const assignedPack = await this.assignActivePack(draftDocument, 'a');

    this.globals.isBusy.showMessage = false;
    this.globals.isBusy.status = false;
  }
  private async startDraft_RochesterDraft(draftDocument: IDraft, activeUsers: IOnlineUser[]) {
    // set app as busy
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;

    // update draft status to show players that products are being prepared
    await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);
    // locally update the statusCode as well
    draftDocument.statusCode++;

    // seat players
    console.log('Seat active players');
    draftDocument = await this.seatPlayersForDraft(draftDocument, activeUsers);

    // generate boosters
    console.log('Generating boosters for all active players');
    this.globals.isBusy.message = 'Generating boosters for all active players';
    const boosters: IBoosterPackList = await this.generateBoosters(draftDocument);

    // assign boosters to players
    console.log('Boosters generated, assign boosters to players');
    this.globals.isBusy.message = 'Assigning boosters to players';
    const assignedBoosters = await this.assignBoostersToPlayers(draftDocument, boosters);

    // update status
    this.globals.isBusy.message = 'Updating draft status';
    const statusUpdated = await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);

    // start
    this.globals.isBusy.message = 'Starting the draft';
    const assignedPack = await this.assignActivePack(draftDocument, 'a');
    const assignedActivePlayerAndBooster = await this.assignActivePlayerAndBooster(draftDocument, draftDocument.seatedPlayers[0], boosters.packA[0]);

    this.globals.isBusy.showMessage = false;
    this.globals.isBusy.status = false;
  }
  private async startDraft_RotisserieDraft(draftDocument: IDraft, activeUsers: IOnlineUser[]) {
    // set app as busy
    this.globals.isBusy.status = true;
    this.globals.isBusy.showMessage = true;

    // update draft status to show players that products are being prepared
    await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);
    // locally update the statusCode as well
    draftDocument.statusCode++;

    // seat players
    console.log('Seat players');
    draftDocument = await this.seatPlayersForDraft(draftDocument, activeUsers);

    // generate the ONE booster
    console.log('Generate the list of cards to be used in the rotisserie draft');
    this.globals.isBusy.message = 'Generating list of cards';
    const listOfCards = await this.generateListOfCards(draftDocument);
    console.log(`...${listOfCards} card documents saved in the database`);

    // update status
    this.globals.isBusy.message = 'Updating draft status';
    const statusUpdated = await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);

    // start
    this.globals.isBusy.message = 'Starting the draft';
    const assignedStartingPlayer = await this.assignActivePlayerAndBooster(draftDocument, draftDocument.seatedPlayers[0], null);

    this.globals.isBusy.showMessage = false;
    this.globals.isBusy.status = false;
  }
  private seatPlayersForDraft(draft: IDraft, activeUsers: IOnlineUser[]): Promise<IDraft> {

    return new Promise(async (resolve, reject) => {

      console.log('Player list to be seating', draft.playerDocIds, activeUsers);

      // store a local copy of the player list
      const playersToSeat: string[] = JSON.parse(JSON.stringify(draft.playerDocIds));

      if (draft.isLive) {
        // remnove any inactive users
        for await (const playerDocId of draft.playerDocIds) {
          // check if playerDocId is present among active users
          if (activeUsers.find((u) => u.playerDocId === playerDocId) === undefined) {
            console.log(`Player with id: ${playerDocId} is not active, remove from seating list`);
            playersToSeat.splice(playersToSeat.indexOf(playerDocId), 1);
          }
        }
      }

      // shuffle players before seating
      playersToSeat.sort(() => Math.random() - 0.5);

      draft.seatedPlayers = playersToSeat;

      console.log('Players actually seated', draft.seatedPlayers);

      console.log('Updating player list with new seatings');
      const update = await this.afs.collection('drafts').doc(draft.docId)
        .update({
          seatedPlayers: playersToSeat,
        })
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });

      resolve(draft);

    });

  }
  private generateListOfCards(draft: IDraft): Promise<number> {

    return new Promise(async (resolve) => {
      // create the cards array
      const cards: IBoosterCard[] = [];

      // loop through all sets to get the cards
      for await (const set of draft.sets) {
        console.log(`...fetching cards from ${set.name}`);
        await this.getSetCards(set)
          .then((cardsInSet) => {
            console.log(`...total cards ${cardsInSet.length}`);
            // filter cards before adding them to the cards list
            if (!draft.includeAnteCards) {
              const filteredCards = cardsInSet.filter((c) => c.text.includes('not playing for ante')).length;
              console.log(`...filter away ${filteredCards} ante cards`);
              cardsInSet = cardsInSet.filter((c) => c.text.includes('not playing for ante') === false);
            }
            if (!draft.includeBasicLands) {
              const filteredCards = cardsInSet.filter((c) => c.type.includes('Basic Land')).length;
              console.log(`...filter away ${filteredCards} basic land cards`);
              cardsInSet = cardsInSet.filter((c) => c.type.includes('Basic Land') === false);
            }
            console.log(`...new total ${cardsInSet.length}`);

            // add the cards to the list of cards
            cardsInSet.forEach((card) => cards.push(card));
          })
          .catch((err) => console.log(err));
      }

      let cardCounter = 0;
      let cardMap = {};
      for await (const card of cards) {
        // create the document object
        const cardDoc: IBoosterCardDocument = {
          docId: uuidv4(),
          savedByPlayerDocIds: [],
          card: card
        }

        cardCounter++;

        // save the card in the map
        // cardMap[cardDoc.docId] = cardDoc;

        // save the cards
        await this.afs.collection('drafts').doc(draft.docId).collection('cards').doc(cardDoc.docId)
          .set(cardDoc)
          .then((res) => { this.globals.isBusy.message = `[${cardCounter}/${cards.length}]\n\n${cardDoc.card.name} saved` })
          .catch((err) => console.log(err))
      }

      // save the cardMap


      resolve(cardCounter);

    });


  }
  private getSetCards(set: IDraftSet): Promise<IBoosterCard[]> {
    return new Promise((resolve) => {

      // get set file
      const ref = this.storage.ref('set-files/' + set.code + '.json');
      ref.getDownloadURL().pipe(
        switchMap((url: string) => {
          return this.http.get(url);
        }),
        take(1),
      ).subscribe(async (setFile: any) => {

        console.log(setFile);
        // create the return object
        const cardList: IBoosterCard[] = [];

        // create all the card objects
        for await (const card of setFile.data.cards) {
          const cardObject = this.createCardObject(card, set.keyruneCode, set.cardModel === 'mtgjson');
          console.log(cardObject);
          cardList.push(cardObject);
        }

        resolve(cardList);

      });

    });
  }
  private generateBoosters(draft: IDraft): Promise<IBoosterPackList> {

    return new Promise(async (resolve, reject) => {

      if (draft.isRotisserie) {

      }

      if (draft.isBooster || draft.isRochester) {

        // check if it's a CUBE draft and all unique Boosters should be generated
        if (draft.packs.a.isCube && draft.packs.a.uniqueBoosters) {
          // call the create boosters with the total of booster to create directly
          const packs: IBoosterPackList = await this.createUniqueBoosters(draft, draft.seatedPlayers.length, draft.docId);
          resolve(packs);
        }
        else {
          // PACK A
          console.log('creating boosters for pack A');
          this.globals.isBusy.message = `Generating boosters for all active players\n\nCreating pack A (${draft.packs.a.name})`;
          const packA: string[] = await this.createBoosters(draft.packs.a, 'a', draft.seatedPlayers.length, draft.docId);

          // PACK B
          console.log('creating boosters for pack B');
          this.globals.isBusy.message = `Generating boosters for all active players\n\nCreating pack B (${draft.packs.b.name})`;
          const packB: string[] = await this.createBoosters(draft.packs.b, 'b', draft.seatedPlayers.length, draft.docId);

          // PACK C
          console.log('creating boosters for pack C');
          this.globals.isBusy.message = `Generating boosters for all active players\n\nCreating pack C (${draft.packs.c.name})`;
          const packC: string[] = await this.createBoosters(draft.packs.c, 'c', draft.seatedPlayers.length, draft.docId);

          // packs done, return
          resolve({
            packA,
            packB,
            packC,
          });
        }

      }
    });

  }
  private createBoosters(set: IDraftSet, pack: string, packsToCreate: number, draftDocId: string): Promise<string[]> {

    return new Promise((resolve, reject) => {
      // get set file
      const ref = this.storage.ref('set-files/' + set.code + '.json');
      ref.getDownloadURL().pipe(
        switchMap((url: string) => {
          return this.http.get(url);
        }),
        take(1),
      ).subscribe(async (setFile: any) => {

        // create array to hold all boster id's
        const boosterDocIds: string[] = [];

        console.log({
          set,
          draftDocId,
          packsToCreate,
        });

        console.log(`starting loop to create boosters for each pack in the draft`);

        // for each pack to create, create a booster and save it in the sub collection for packs
        for await (const packNum of Array.from(Array(packsToCreate).keys())) {

          // create the booster
          console.log(`1. getting booster cards for pack ${packNum + 1} of ${packsToCreate}`);
          const boosterCards = await this.getBoosterCards(setFile);
          console.log(`2. cards for pack ${packNum + 1} fetched`);
          console.log(boosterCards);

          // save the booster
          console.log(`3. saving booster pack ${packNum + 1}`);
          const saveBooster = await this.saveBooster(draftDocId, pack, set.code, set.keyruneCode, boosterCards);
          console.log(`4. booster pack ${packNum + 1} saved widh DocId: ${saveBooster}`);

          boosterDocIds.push(saveBooster);

        }

        console.log(`${packsToCreate} packs for pack ${pack} created`);

        resolve(boosterDocIds);
      });

    });

  }
  private async createUniqueBoosters(draft: IDraft, seatedPlayerCount: number, draftDocId: string): Promise<IBoosterPackList> {

    return new Promise((resolve) => {

      // just make sure all the packs are the same set
      if (draft.packs.a.code === draft.packs.b.code && draft.packs.a.code === draft.packs.c.code) {
        // get the cards (all packs will be the same set as its cube)
        const ref = this.storage.ref('set-files/' + draft.packs.a.code + '.json');
        ref.getDownloadURL().pipe(
          switchMap((url: string) => {
            return this.http.get(url);
          }),
          take(1),
        ).subscribe(async (setFile: any) => {

          // create array to hold all boster id's
          const boosterDocIds: string[] = [];

          // calculate the number of cards needed
          const cardsNeeded = (draft.packs.a.boosterSize * 3) * seatedPlayerCount;
          console.log(cardsNeeded);

          // get a list of cards
          const cards = setFile.data.cards;
          const cardsToPack = cards.sort(() => Math.random() - 0.5).splice(0, cardsNeeded);
          const cardPool: IBoosterCard[] = [];
          for await (const card of cardsToPack) {
            cardPool.push(this.createCardObject(card, draft.packs.a.keyruneCode, false));
          }
          console.log(cardPool);

          // loop through all packs and create a booster for each seated player
          for await (const [packName, pack] of Object.entries(draft.packs)) {

            for await (const player of draft.seatedPlayers) {

              // get the cards for the booster
              console.log(`1. getting booster cards for pack ${packName} and player ${player}`);
              const boosterCards = cardPool.splice(0, pack.boosterSize);

              // save the booster
              console.log(`3. saving booster pack`);
              const boosterDocId = await this.saveBooster(draftDocId, packName, pack.code, pack.keyruneCode, boosterCards);
              console.log(`4. booster pack saved widh DocId: ${boosterDocId}`);

              boosterDocIds.push(boosterDocId);

            }

          }

          const response: IBoosterPackList = {
            packA: boosterDocIds.splice(0, seatedPlayerCount),
            packB: boosterDocIds.splice(0, seatedPlayerCount),
            packC: boosterDocIds.splice(0, seatedPlayerCount)
          }

          console.log(response);
          resolve(response);

        });
      }
      else {
        resolve(null);
      }
    });


  }
  private async getBoosterCards(setFile: any) {

    if (
      setFile.data.code.toLowerCase() === 'xspc' ||
      setFile.data.code.toLowerCase() === 'xscr'
    ) {

      // get booster weight list
      const weightList = this.getBoosterWeightList(setFile.data.booster.default.boosters);

      // get a random weight number
      const weightNumber = this.getRandomWeightNumber(setFile.data.booster.default.boostersTotalWeight);

      // get booster content according to the random weight number
      const contents = this.getBoosterContents(weightList, weightNumber);

      // build up the booster with card according to content
      const customBbooster = await this.getCardsInBoosterCustom(contents, setFile);
      return customBbooster;
    }
    else {

      const booster = await this.getCardsInBooster(setFile);

      return booster;
    }

  }
  private async getCardsInBooster(setFile: any): Promise<IBoosterCard[]> {
    const keyruneCode = setFile.data.keyruneCode;
    const booster: IBoosterCard[] = [];

    // get booster from magicthegathering.io
    const url = 'https://api.magicthegathering.io/v1/sets/' + setFile.data.code + '/booster';
    const response: any = await this.http.get(url).toPromise();
    console.log(response);
    const tmpBooster = response.cards;

    // for each card in the response, create a card object
    for await (const card of tmpBooster) {
      booster.push(this.createCardObject(card, keyruneCode, false));
    }

    // return the booster
    return booster;

  }
  private createCardObject(card: any, keyruneCode: string, mtgjsonstructure: boolean): IBoosterCard {
    // console.log(card);
    const scryfallUrl = 'https://api.scryfall.com/cards/multiverse/';
    const scryfallParams = '?format=image';
    let multiverseid: string;
    let cardId: string;

    if (mtgjsonstructure) {
      multiverseid = card.identifiers ? card.identifiers.multiverseId : card.multiverseId ? card.multiverseId : 'no-multiverseid';
      cardId = card.uuid;
    }
    else {
      multiverseid = card.multiverseid;
      cardId = card.id;
    }

    // Spectal Chaos Special Fucking Code!
    if (card.setcode === 'xspc') {
      cardId = card.id;
    }

    // const scryfallUrl = 'https://c1.scryfall.com/file/scryfall-cards/normal/front/';
    // const scryfallParams = card.identifiers.scryfallId.substring(0, 1) + '/' + card.identifiers.scryfallId.substring(1, 2) + '/' + card.identifiers.scryfallId + '.jpg';

    const imageUrl = scryfallUrl + multiverseid + scryfallParams;
    return {
      name: card.name,
      imageUrl: card.setcode === 'xspc' ? 'assets/cards/large/xspc/' + cardId + '.jpg' : imageUrl,
      isPicked: false,
      pickedByPlayerDocId: '',
      cardId,
      // scryfallCardId: card.identifiers.scryfallId,
      // convertedManaCost: card.convertedManaCost,
      multiverseid: multiverseid ? multiverseid : '',
      convertedManaCost: card.cmc ? card.cmc : null,
      manaCost: card.manaCost ? card.manaCost : '',
      types: card.types ? card.types : [],
      type: card.type ? card.type : '',
      text: card.text ? card.text : '',
      power: card.power ? card.power : '',
      toughness: card.toughness ? card.toughness : '',
      typeSymbol: this.getTypeSymbol(card.types),
      colors: card.colors ? card.colors : [],
      keyruneCode: keyruneCode ? keyruneCode : card.setcode
    };
  }
  private getTypeSymbol(types: string[]): string {
    if (types === undefined) { return ''; }

    // Land
    if (types.includes('Land')) { return 'land'; }
    // Creature
    if (types.includes('Creature')) { return 'creature'; }
    // Enchantment
    if (types.includes('Enchantment')) { return 'enchantment'; }
    // Artifact
    if (types.includes('Artifact')) { return 'artifact'; }
    // Instant
    if (types.includes('Instant')) { return 'instant'; }
    // Sorcery
    if (types.includes('Sorcery')) { return 'sorcery'; }
    // Scheme
    if (types.includes('Scheme')) { return 'scheme'; }
    // Conspiracy
    if (types.includes('Conspiracy')) { return 'conspiracy'; }
    // Plane
    if (types.includes('Plane')) { return 'plane'; }
    // Planeswalker
    if (types.includes('Planeswalker')) { return 'planeswalker'; }
    // Vanguard
    if (types.includes('Vanguard')) { return 'vanguard'; }
    // Phenomenon
    if (types.includes('Phenomenon')) { return 'phenomenon'; }
    // Token
    if (types.includes('Token')) { return 'token'; }

    // something elese
    return '';
  }
  private async saveBooster(draftDocId: string, pack: string, setCode: string, keyruneCode: string, cards: Array<IBoosterCard>): Promise<string> {

    const docId = uuidv4();

    // create the booster object
    const boosterObject: IBooster = {
      docId,
      pack,
      setCode,
      keyruneCode,
      openingPackPlayerDocId: null,
      activePlayerDocId: null,
      nextPlayerDocId: null,
      latestPickPlayerDocId: null,
      cards,
      latestPick: null
    };

    // save the booster to firebase
    console.log('About to save the booster', boosterObject);
    const result = await this.afs.collection('drafts').doc(draftDocId).collection('boosters').doc(docId).set(boosterObject)
      .then(() => {
        return docId;
      })
      .catch((err) => {
        console.log(err);
        return null;
      });

    return result;

  }
  private assignBoostersToPlayers(draftDocument: IDraft, boosters: IBoosterPackList): Promise<boolean> {

    return new Promise(async (resolve, reject) => {

      // loop through all packs (a/b/c)
      for await (const [key, packDocIds] of Object.entries(boosters)) {

        // loop through all boosters for the specific pack (a/b/c)
        for await (const boosterDocId of packDocIds) {
          // store the index
          const boosterIndex = packDocIds.findIndex((x: string) => x === boosterDocId);
          // update the booster document with the matching player index (booster index 0 goes to player index 0 etc)
          const assigned = await this.assignStartingPlayerToBooster(draftDocument.docId, boosterDocId, draftDocument.seatedPlayers[boosterIndex]);
          console.log(`Pack ${key} | Booster ${boosterIndex + 1} (${boosterDocId}) assinged to be opened by ${draftDocument.seatedPlayers[boosterIndex]} : ${assigned}`);
        }

      }

      resolve(true);
    });

  }
  private async assignStartingPlayerToBooster(draftDocId: string, boosterDocId: string, playerDocId: string): Promise<boolean> {

    // update booster document with playerDocId (openingPackPlayerDocId) || SubCollection
    const result = await this.afs.collection('drafts').doc(draftDocId).collection('boosters').doc(boosterDocId)
      .update({
        openingPackPlayerDocId: playerDocId,
        activePlayerDocId: playerDocId
      })
      .then(() => {
        return true;
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    return result;

  }
  public assignActivePack(draft: IDraft, pack: string): Promise<boolean> {

    return new Promise(async (resolve) => {

      const update: any = {};
      update.activePack = pack;

      // do the update
      const result = this.afs.collection('drafts').doc(draft.docId)
        .update(update)
        .then(() => {
          return true;
        })
        .catch((err) => {
          console.log(err);
          return false;
        });

      resolve(result);

    });

  }
  public assignActivePlayerAndBooster(draft: IDraft, playerDocId: string, boosterDocId: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.afs.collection('drafts').doc(draft.docId)
        .update({
          activePlayerDocId: playerDocId,
          activeBoosterDocId: boosterDocId
        })
        .then((res) => {
          console.log(res);
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          resolve(false);
        })
    });
  }
  public async pickCard(draft: IDraft, booster: IBooster, card: IBoosterCard, cardDoc: IBoosterCardDocument = null, cardDocs: IBoosterCardDocument[] = []) {

    const timestamp = firestore.Timestamp.now().seconds;
    const cardName = booster === null ? cardDoc.card.name : booster.cards.find(c => c.cardId === card.cardId).name;
    console.log(`about to pick ${cardName}`);
    let boosterBefore: IBooster;
    let boosterAfter: IBooster;

    // update the draft document
    await this.afs.collection('drafts').doc(draft.docId)
      .update({
        activePlayerDocId: null
      })
      .then(() => {
        console.log('draft document updated');
      })
      .catch((err) => {
        console.log(err);
        this.toastService.show(`Error when updating draft document with active player`, { classname: 'error-toast', delay: 5000 });
      });


    // as long as it's not ROTISSERIE draft, update the booster
    if (!draft.isRotisserie) {

      // store the booster as before the pick
      boosterBefore = JSON.parse(JSON.stringify(booster));

      // mark card as picked
      booster.cards.find(c => c.cardId === card.cardId).isPicked = true;
      // set player who picked the card
      booster.cards.find(c => c.cardId === card.cardId).pickedByPlayerDocId = this.auth.user.playerId;
      booster.latestPickPlayerDocId = this.auth.user.playerId;
      // set timestamp for the pick
      booster.latestPick = timestamp;
      // inactivate the booster
      booster.activePlayerDocId = null;

      // set the booster as after the pick
      boosterAfter = JSON.parse(JSON.stringify(booster));
      // update the booster
      await this.afs.collection('drafts').doc(draft.docId).collection('boosters').doc(booster.docId)
        .set(booster)
        .then(() => {
          console.log('booster updated');
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(`Error when updating booster and the picked card ${cardName}`, { classname: 'error-toast', delay: 5000 });
        });
    }
    else {
      // update the card
      await this.afs.collection('drafts').doc(draft.docId).collection('cards').doc(cardDoc.docId)
        .update({
          'card.pickedByPlayerDocId': this.auth.user.playerId,
          'card.isPicked': true
        })
        .then(() => {
          console.log('card updated');
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(`Error when updating booster and the picked card ${cardName}`, { classname: 'error-toast', delay: 5000 });
        });
    }

    // add pick
    const pick: IPick = {
      docId: uuidv4(),
      playerDocId: this.auth.user.playerId,
      pack: draft.activePack,
      draftDocId: draft.docId,
      boosterDocId: booster === null ? null : booster.docId,
      card,
      pickedTimestamp: timestamp
    };

    // save the pick document
    await this.afs.collection('drafts').doc(draft.docId).collection('picks').doc(pick.docId)
      .set(pick)
      .then(() => {
        console.log('pick saved');
        this.toastService.show(`${cardName} picked`, { classname: 'success-toast', delay: 2000 });
      })
      .catch((err) => {
        console.log(err);
        this.toastService.show(`Error when picking ${cardName}`, { classname: 'error-toast', delay: 5000 });
      });

    // perform after pick updates
    await this.afterPickUpdates(draft, boosterBefore, boosterAfter, cardDocs);

  }
  public saveCard(draftDocId: string, cardDocId: string): Promise<boolean> {

    return new Promise((resolve) => {

      this.afs.collection('drafts').doc(draftDocId).collection('cards').doc(cardDocId)
        .update({
          savedByPlayerDocIds: firestore.arrayUnion(this.auth.user.playerId)
        })
        .then((res) => {
          this.toastService.show(`Card successfully saved.`, { classname: 'success-toast', delay: 2000 });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(`Something went wrong when trying to save the card, please try again.`, { classname: 'error-toast', delay: 5000 });
          resolve(false);
        });

    });

  }
  public unsaveCard(draftDocId: string, cardDocId: string): Promise<boolean> {

    return new Promise((resolve) => {

      this.afs.collection('drafts').doc(draftDocId).collection('cards').doc(cardDocId)
        .update({
          savedByPlayerDocIds: firestore.arrayRemove(this.auth.user.playerId)
        })
        .then((res) => {
          this.toastService.show(`Card successfully removed from the list of saved cards.`, { classname: 'success-toast', delay: 2000 });
          resolve(true);
        })
        .catch((err) => {
          console.log(err);
          this.toastService.show(`Something went wrong when trying to remove the card from the list of saved cards, please try again.`, { classname: 'error-toast', delay: 5000 });
          resolve(false);
        });

    });

  }
  private async afterPickUpdates(draftDocument: IDraft, boosterBefore: IBooster, boosterAfter: IBooster, cardDocs: IBoosterCardDocument[] = []) {
    // continue handle the pick according to draft type
    if (draftDocument.isBooster) {
      console.log(`::: DRAFT === BOOSTER DRAFT`);
      await this.handlePickedCard_BoosterDraft(draftDocument, boosterBefore, boosterAfter)
        .then((res) => console.log(`... ALL DONE`))
        .catch((err) => console.log(err));
    }
    if (draftDocument.isRochester) {
      console.log(`::: DRAFT === ROCHESTER DRAFT`);
      await this.handlePickedCard_RochesterDraft(draftDocument, boosterBefore, boosterAfter)
        .then((res) => console.log(`... ALL DONE`))
        .catch((err) => console.log(err));
    }
    if (draftDocument.isRotisserie) {
      console.log(`::: DRAFT === ROTISSERIE DRAFT`);
      const numberOfPicks = cardDocs.filter((c) => c.card.pickedByPlayerDocId === this.auth.user.playerId).length + 1;
      const allDone = cardDocs.filter((c) => c.card.isPicked).length === (draftDocument.numberOfPicks * draftDocument.seatedPlayers.length);
      await this.handlePickedCard_RotisserieDraft(draftDocument, this.auth.user.playerId, numberOfPicks, allDone)
        .then((res) => console.log(`... ALL DONE`))
        .catch((err) => console.log(err));
    }
  }
  private handlePickedCard_RotisserieDraft(draftDocument: IDraft, playerDocIdWhoPicked: string, pickNumber: number, allDone: boolean): Promise<boolean> {
    return new Promise((resolve) => {

      // first player will pass to the right after ODD number of picks and pick again after EVEN number of picks
      // last player will pass to the left after EVEN number of picks and pick again after ODD number of picks
      // all other will pass right on ODD number of picks and left on EVEN number of picks

      const playerWhoPickedSeatingIndex = draftDocument.seatedPlayers.indexOf(playerDocIdWhoPicked);
      const isFirstPlayer = playerWhoPickedSeatingIndex === 0;
      const isLastPlayer = playerWhoPickedSeatingIndex === draftDocument.seatedPlayers.length - 1;
      let playerDocIdToPickNext: string;

      if (isFirstPlayer && pickNumber % 2 !== 0) {
        console.log('player is FIRST and pick is ODD >>> pass to the right');
        playerDocIdToPickNext = draftDocument.seatedPlayers[playerWhoPickedSeatingIndex + 1];
      }
      else if (isFirstPlayer && pickNumber % 2 === 0) {
        console.log('player is FIRST and pick is EVEN >>> pick again');
        playerDocIdToPickNext = playerDocIdWhoPicked;
      }
      else if (isLastPlayer && pickNumber % 2 !== 0) {
        console.log('player is LAST and pick is ODD >>> pick again');
        playerDocIdToPickNext = playerDocIdWhoPicked;
      }
      else if (isLastPlayer && pickNumber % 2 === 0) {
        console.log('player is LAST and pick is EVEN >>> pass to the LEFT');
        playerDocIdToPickNext = draftDocument.seatedPlayers[playerWhoPickedSeatingIndex - 1];
      }
      else if (!isFirstPlayer && !isLastPlayer && pickNumber % 2 === 0) {
        console.log('player is OTHER and pick is EVEN >>> pass to the LEFT');
        playerDocIdToPickNext = draftDocument.seatedPlayers[playerWhoPickedSeatingIndex - 1];
      }
      else if (!isFirstPlayer && !isLastPlayer && pickNumber % 2 !== 0) {
        console.log('player is OTHER and pick is ODD >>> pass to the RIGHT');
        playerDocIdToPickNext = draftDocument.seatedPlayers[playerWhoPickedSeatingIndex + 1];
      }

      if (!allDone) {
        this.assignActivePlayerAndBooster(draftDocument, playerDocIdToPickNext, null)
          .then((res) => {
            resolve(true);
          })
          .catch((err) => {
            console.log(err);
            resolve(false);
          });
      }
      else {
        this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);
      }

    });
  }
  private handlePickedCard_BoosterDraft(draftDocument: IDraft, boosterBefore: IBooster, boosterAfter: IBooster): Promise<boolean> {
    return new Promise((resolve) => {

      const playerSeatingIndex = draftDocument.seatedPlayers.indexOf(this.auth.user.playerId);
      let nextPlayerDocId = null;
      // passing booster to the LEFT
      if (draftDocument.activePack === 'a' || draftDocument.activePack === 'c') {
        // check if player is fist seat
        if (playerSeatingIndex === 0) {
          // pass the booster to last seated player
          nextPlayerDocId = draftDocument.seatedPlayers[draftDocument.seatedPlayers.length - 1];
        }
        else {
          // pass the booster to the player seated below the current player
          nextPlayerDocId = draftDocument.seatedPlayers[playerSeatingIndex - 1];
        }
      }
      // passing booster to the RIGHT
      else if (draftDocument.activePack === 'b') {
        // check if player is last seat
        if (playerSeatingIndex === (draftDocument.seatedPlayers.length - 1)) {
          // pass the booster to first seated player
          nextPlayerDocId = draftDocument.seatedPlayers[0];
        }
        else {
          // pass the booster to the player seated below the current player
          nextPlayerDocId = draftDocument.seatedPlayers[playerSeatingIndex + 1];
        }
      }

      // update booster with next player doc id
      this.afs.collection('drafts').doc(draftDocument.docId).collection('boosters').doc(boosterBefore.docId)
        .update({ nextPlayerDocId })
        .then((res) => console.log(res))
        .catch((err) => console.log(err));

      // check if all cards are picked
      console.log('...checking if all cards are picked')
      if (boosterAfter.cards.filter(c => !c.isPicked).length === 0) {
        // get the pack and check all other packs
        console.log('...all cards picked, lets check all other boosters');
        const pack = boosterAfter.pack;
        this.getBoostersForDraft(draftDocument.docId)
          .pipe(take(1))
          .subscribe(async (boosters) => {
            // check if all packs are done
            if (boosters.filter((b) => b.pack === draftDocument.activePack).filter((b) => b.cards.filter((c) => !c.isPicked).length > 0).length === 0) {
              console.log('...seems all boosters done, lets move on to the next pack');
              let nextPack = undefined;
              switch (pack) {
                case 'a':
                  nextPack = 'b';
                  break;
                case 'b':
                  nextPack = 'c';
                  break;
                case 'c':
                  // nothing
                  break;
              }

              if (nextPack !== undefined) {
                console.log(`...time to activate pack ${pack}`);
                await this.assignActivePack(draftDocument, nextPack)
                  .then((res) => console.log('SUCCESS: draft document updated with new activePack'))
                  .catch((err) => console.log('ERROR: could not update draft document with new activePack', err));
              }
              else {
                console.log('all packs done, lets end the draft');
                await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT)
                  .then((res) => console.log('SUCCESS: draft document updated with new activePack'))
                  .catch((err) => console.log('ERROR: could not update draft document with new activePack'));
              }
            }
            else {
              console.log('...seems all cards have been picked, lets move on to the next pack');
            }

          });
      }
      else {
        console.log('...there are still unpicked cards, nothing to do here');
      }

      resolve(true);
    });
  }
  private handlePickedCard_RochesterDraft(draftDocument: IDraft, boosterBefore: IBooster, boosterAfter: IBooster): Promise<boolean> {

    return new Promise((resolve) => {
      // check if all cards in the booster are picked
      if (boosterAfter.cards.filter((cards) => !cards.isPicked).length === 0) {
        console.log(`...all cards are picked, lets open the next pack`);
        console.log(`...getting all boosters`);
        const boosters$: Observable<IBooster[]> = this.getBoostersForDraft(draftDocument.docId);
        boosters$.pipe(take(1))
          .subscribe(async (boosters) => {
            console.log(JSON.stringify(boosters));
            // check if there are any boosters
            if (boosters.length > 0) {
              // check if all boosters for active pack has been finalized
              if (boosters.filter((b) => b.pack === draftDocument.activePack).filter((b) => b.cards.filter((c) => !c.isPicked).length > 0).length === 0) {
                console.log(`...seems to be no booster from pack ${draftDocument.activePack} left with unpicked cards, move on to the next pack`);
                // update draft document with next pack or finished status
                if (draftDocument.activePack === 'c') {
                  console.log(`...pack C is finished, lets wrap this draft up!`);
                  await this.updateDraftStatus(draftDocument.docId, draftDocument.statusCode, DraftStatusChange.NEXT);
                }
                else {
                  const nextPlayerDocId = boosterAfter.latestPickPlayerDocId;
                  let nextBoosterDocId = null;
                  let nextPack = null;
                  if (draftDocument.activePack === 'a') {
                    console.log(`...pack A is finised, moving on to pack B`);
                    nextPack = 'b';
                    nextBoosterDocId = boosters.find((b) => b.openingPackPlayerDocId === boosterAfter.latestPickPlayerDocId && b.pack === nextPack).docId;
                    console.log(`...pack B and booster with id: ${nextBoosterDocId} will be assigned`);
                  }
                  if (draftDocument.activePack === 'b') {
                    console.log(`...pack B is finised, moving on to pack C`);
                    nextPack = 'c';
                    nextBoosterDocId = boosters.find((b) => b.openingPackPlayerDocId === boosterAfter.latestPickPlayerDocId && b.pack === nextPack).docId;
                    console.log(`...pack C and booster with id: ${nextBoosterDocId} will be assigned`);
                  }
                  await this.assignActivePack(draftDocument, nextPack);
                  await this.assignActivePlayerAndBooster(draftDocument, nextPlayerDocId, nextBoosterDocId);
                }
              }
              else {
                console.log(`...found some unfinished boosters`);
                // check if the player who picked the last pick was the player who opened the pack
                let playerToOpenNextBooster = null;
                if (boosterAfter.latestPickPlayerDocId === boosterAfter.openingPackPlayerDocId) {
                  console.log(`...player who opened the pack also finised it, we need to move to the next player`);
                  playerToOpenNextBooster = this.getPassToPlayerDocId(draftDocument, boosterBefore, boosterAfter, true);
                }
                else {
                  playerToOpenNextBooster = boosterAfter.latestPickPlayerDocId;
                }
                const nextBoosterDocId = boosters.find((b) => b.pack === draftDocument.activePack && b.openingPackPlayerDocId === playerToOpenNextBooster).docId;
                // performe the update
                await this.assignActivePlayerAndBooster(draftDocument, playerToOpenNextBooster, nextBoosterDocId);
              }
            }
            else {
              console.log(`...could not find any boosters, please try again`);
            }
          });

      }
      else {
        const passToPlayerDocId = this.getPassToPlayerDocId(draftDocument, boosterBefore, boosterAfter);

        // update draft document
        this.assignActivePlayerAndBooster(draftDocument, passToPlayerDocId, boosterAfter.docId)
          .then((res) => {
            resolve(res);
          });

      }

    });

  }
  private getPassToPlayerDocId(draftDocument: IDraft, boosterBefore: IBooster, boosterAfter: IBooster, defineNextToOpenBooster: boolean = false): string {
    console.log(`...there are still cards to be picked, lets decide who shall make the next pick`);
    const isFistPick = boosterAfter.cards.filter((c) => c.isPicked).length === 1;
    console.log(`...isFistPick of the booster: ${isFistPick}`);
    const passingLeft = draftDocument.activePack === 'a' || draftDocument.activePack === 'c';
    const indexFirst = 0;
    const indexLast = draftDocument.seatedPlayers.length - 1;
    const playerDocId_OpenedPack = boosterAfter.openingPackPlayerDocId;
    const seatingIndex_OpenedPack = draftDocument.seatedPlayers.indexOf(playerDocId_OpenedPack);
    const playerDocId_PickedCard = boosterAfter.latestPickPlayerDocId;
    const playerIndex_PickedCard = draftDocument.seatedPlayers.indexOf(playerDocId_PickedCard);
    const playerWhoPickedNumPicks = boosterAfter.cards.filter((c) => c.pickedByPlayerDocId === playerDocId_PickedCard).length;
    const seatingIndex_TurningPack = passingLeft
      ? seatingIndex_OpenedPack === indexLast ? indexFirst : seatingIndex_OpenedPack + 1
      : seatingIndex_OpenedPack === indexFirst ? indexLast : seatingIndex_OpenedPack - 1;
    const playerDocId_TurningPack = draftDocument.seatedPlayers[seatingIndex_TurningPack];

    const isTurningPlayer = playerDocId_OpenedPack === playerDocId_PickedCard || playerDocId_TurningPack === playerDocId_PickedCard;
    const isConsecutivePick = boosterBefore.latestPickPlayerDocId === playerDocId_PickedCard;
    console.log(`...this pick will be a consecutive pick: ${isConsecutivePick}`);
    const passToTheLeft = passingLeft ? playerWhoPickedNumPicks % 2 !== 0 : playerWhoPickedNumPicks % 2 === 0;

    let passToPlayerIndex = 0;
    if (isTurningPlayer && !isConsecutivePick && !isFistPick && !defineNextToOpenBooster) {
      // pass booster to self
      passToPlayerIndex = playerIndex_PickedCard;
      console.log(`...booster will turn here`);
    }
    else {
      // pass booster to next
      if (passToTheLeft) {
        // passing booster DOWN in the array
        passToPlayerIndex = playerIndex_PickedCard - 1 < 0 ? indexLast : playerIndex_PickedCard - 1;
        console.log(`...next player will be the player to the ::: LEFT <<<`);
      }
      else {
        // passing booster UP in the array
        passToPlayerIndex = playerIndex_PickedCard + 1 > indexLast ? indexFirst : playerIndex_PickedCard + 1;
        console.log(`...next player will be the player to the ::: RIGHT >>>`);
      }
    }

    const passToPlayerDocId = draftDocument.seatedPlayers[passToPlayerIndex];

    return passToPlayerDocId;
  }
  public pickUpBooster(draftDocId: string, booster: IBooster) {
    console.log('[TESTING] updating booster data');
    this.afs.collection('drafts').doc(draftDocId).collection('boosters').doc(booster.docId)
      .update({
        activePlayerDocId: this.auth.user.playerId,
        nextPlayerDocId: null
      })
      .then(() => {
        console.log(`${booster.docId} picked up by ${this.auth.user.playerId}`);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  public addSet(set: IDraftSet): Promise<boolean> {
    return new Promise((resolve) => {
      this.afs.collection('draftSets').doc(set.docId).set(set)
        .then(() => resolve(true))
        .catch((err) => {
          this.toastService.show('Could not save set in the database, please try again', { classname: 'error-toast', delay: 6000 })
          console.log(err);
          resolve(false);
        });
    });
  }
  private getRandomArbitrary(max: number) {
    return Math.round(Math.random() * (max - 1) + 1);
  }
  private getBoosterWeightList(boosters: any) {

    boosters.sort((n1, n2) => n1.weight - n2.weight);

    const boosterWeights = [];

    for (const [index, booster] of boosters.entries()) {

      if (index > 0) {
        // console.log(booster.weight);
        // console.log('add:', booster.weight, boosterWeights[0]);
        boosterWeights.unshift({
          contents: booster.contents,
          weightLimit: booster.weight + boosterWeights[0].weightLimit,
        });
      } else {
        // console.log(booster.weight);
        boosterWeights.unshift({
          contents: booster.contents,
          weightLimit: booster.weight,
        });

      }
    }

    // console.log(boosterWeights);

    return boosterWeights;

  }
  private getRandomWeightNumber(totalWeight: number) {
    return Math.floor(Math.random() * totalWeight);
  }
  private getBoosterContents(weightList: any[], weightNumber: number) {
    let latestCheck = 0;
    for (const [index, weightListItem] of weightList.entries()) {
      if (weightNumber < weightListItem.weightLimit) {
        latestCheck = index;
      }
    }

    return weightList[latestCheck];
  }
  private async getCardsInBoosterCustom(boosterContents: any, setFile: any): Promise<IBoosterCard[]> {
    const cards = setFile.data.cards;
    const keyruneCode = setFile.data.keyruneCode;
    const contents = await boosterContents.contents;
    // console.log(contents);
    const booster: IBoosterCard[] = [];
    const tmpBooster = {
      common: [],
      uncommon: [],
      rare: [],
    };

    for (const [key, value] of Object.entries(contents)) {
      console.log(key, value);

      // temporary store the cards as a new object
      const tmpCards: any = JSON.parse(JSON.stringify(cards));

      // randomize the cards
      tmpCards.sort(() => Math.random() - 0.5);
      console.log(tmpCards);

      // filter the cards on rarity
      let rarity = '';
      switch (key) {
        case 'common':
          rarity = 'common';
          break;
        case 'uncommon':
          rarity = 'uncommon';
          break;
        case 'rare':
          rarity = 'rare';
          break;
        case 'foilCommon':
          rarity = 'common';
          break;
        case 'foilUncommon':
          rarity = 'uncommon';
          break;
        case 'foilRare':
          rarity = 'rare';
          break;
        case 'mythic':
          rarity = 'rare';
          break;
        case 'foilCommonOrBasic':
          // randomize basic or common >>> TODO!!!
          rarity = 'common';
          break;
      }


      // start a counter
      const tmpCardArray: Array<any> = JSON.parse(JSON.stringify(tmpCards.filter((c: any) => c.rarity === rarity)));
      let counter: number = 0;
      let cardIndex = 0;
      while (counter < (value as number)) {

        // get card by index
        const tmpCard = tmpCardArray[cardIndex];
        console.log(tmpCard);

        // Basic Land Checker
        if (tmpCard.type.includes('Basic')) {
          if (tmpBooster[rarity].filter((c: any) => c.type.includes('Basic')).length === 0) {
            tmpBooster[rarity].push(tmpCard);
            counter++;
          }
        }
        else {
          tmpBooster[rarity].push(tmpCard);
          counter++;
        }

        cardIndex++;

      }
      // // get the amount of cards from the temporary object
      // const cardsToPutInBooster: any = tmpCards.filter((c: any) => c.rarity === rarity).splice(0, value);
      // // console.log(cardsToPutInBooster);

      // // add the cards to the booster
      // cardsToPutInBooster.forEach((c: any) => tmpBooster[rarity].push(c));

    }

    // add cards according to rarity
    for await (const card of tmpBooster.rare) {
      booster.push(this.createCardObject(card, keyruneCode, setFile.cardModel === 'mtgjson'));
    }
    for await (const card of tmpBooster.uncommon) {
      booster.push(this.createCardObject(card, keyruneCode, setFile.cardModel === 'mtgjson'));
    }
    for await (const card of tmpBooster.common) {
      booster.push(this.createCardObject(card, keyruneCode, setFile.cardModel === 'mtgjson'));
    }

    // return the booster
    return booster;

  }

}


export interface IBoosterCardDocument {
  docId: string;
  savedByPlayerDocIds: string[];
  card: IBoosterCard;
}
export interface ISetList {
  name: string;
  code: string;
  keyruneCode: string;
  block?: string;
  releaseDate: string;
  totalSetSize: number;
  isCore: boolean;
  isExpansion: boolean;
  isCustom: boolean;
  storageUrl: string;
  downloadUrl: string;
  selected?: boolean;
}
export interface IMtgJsonFile {
  meta: {
    date: string;
    version: string;
  };
  data: any;
  // {
  //   baseSetSize: number;
  //   cards: IMtgJsonCard[];
  //   code: string;
  //   keyruneCode: string;
  //   name: string;
  //   releaseDate: string;
  //   totalSetSize: number;
  //   type: string;
  // };
}
export interface IMtgJsonCard {
  artist: string;
  borderColor: string;
  colorIdentity: string[];
  colors: string[];
  convertedManaCost: number;
  identifiers: {
    mtgjsonV4Id: string;
    scryfallId: string;
    scryfallOracleId: string;
    multiverseId?: string;
  };
  name: string;
  number: string;
  power?: string;
  rarity: string;
  rulings: ICardRuling[];
  setCode: string;
  subtypes: string[];
  supertypes: string[];
  toughness?: string;
  type: string;
  types: string[];
  uuid: string;
}
export interface ICardRuling {
  date: string;
  text: string;
}
export interface ISetsFile {
  sets: ISetList[];
}
export interface ISetListOptions {
  draftable?: boolean;
  multiSelect?: boolean;
}
export interface IBoosterPackList {
  packA: string[];
  packB: string[];
  packC: string[];
}
export interface IDraftListFilters {
  nameString: string;
  status: statusFilter[];
}
export interface statusFilter {
  statusCode: number;
  text: string;
  selected: boolean;
}
