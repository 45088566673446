<div class="main-deck-builder p-2 d-flex flex-row">

  <div #cardList class="card flex-grow-0">
    <div class="card-header p-2">
      <ng-container *ngIf="showCardImage; else cardBack">
        <div class="card-preview-wrapper {{ selectedCardSetCode }}">
          <img [src]="selectedCardImageUri">
        </div>
      </ng-container>
      <ng-template #cardBack>
        <div class="card-preview-wrapper">
          <img src="assets/card-back.jpg">
        </div>
      </ng-template>
    </div>

    <div class="card-filter text-secondary p-3" *ngIf="cardFilter.show">

      <div class="text-large">Filters</div>

      <div class="mt-4">Colors</div>
      <div class="colors-wrapper d-flex">
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.w }"
          ngbTooltip="White" container="body" (click)="cardFilter.color.w = !cardFilter.color.w; filterCards()"><i
            class="ms ss-fw ms-w"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.u }"
          ngbTooltip="Blue" container="body" (click)="cardFilter.color.u = !cardFilter.color.u; filterCards()"><i
            class="ms ss-fw ms-u"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.b }"
          ngbTooltip="Black" container="body" (click)="cardFilter.color.b = !cardFilter.color.b; filterCards()"><i
            class="ms ss-fw ms-b"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.r }"
          ngbTooltip="Red" container="body" (click)="cardFilter.color.r = !cardFilter.color.r; filterCards()"><i
            class="ms ss-fw ms-r"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.g }"
          ngbTooltip="Green" container="body" (click)="cardFilter.color.g = !cardFilter.color.g; filterCards()"><i
            class="ms ss-fw ms-g"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.color.c }"
          ngbTooltip="Colorless" container="body" (click)="cardFilter.color.c = !cardFilter.color.c; filterCards()"><i
            class="ms ss-fw ms-c"></i></button>
      </div>

      <div class="mt-4">Types</div>
      <div class="types-wrapper d-flex">
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.creature }"
          ngbTooltip="Creature" container="body"
          (click)="cardFilter.type.creature     = !cardFilter.type.creature     ; filterCards()"><i
            class="ms ss-fw ms-creature"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.sorcery }"
          ngbTooltip="Sorcery" container="body"
          (click)="cardFilter.type.sorcery      = !cardFilter.type.sorcery      ; filterCards()"><i
            class="ms ss-fw ms-sorcery"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.instant }"
          ngbTooltip="Instant" container="body"
          (click)="cardFilter.type.instant      = !cardFilter.type.instant      ; filterCards()"><i
            class="ms ss-fw ms-instant"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.enchantment }"
          ngbTooltip="Enchantment" container="body"
          (click)="cardFilter.type.enchantment  = !cardFilter.type.enchantment  ; filterCards()"><i
            class="ms ss-fw ms-enchantment"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.artifact }"
          ngbTooltip="Artifact" container="body"
          (click)="cardFilter.type.artifact     = !cardFilter.type.artifact     ; filterCards()"><i
            class="ms ss-fw ms-artifact"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.planeswalker }"
          ngbTooltip="Planeswalker" container="body"
          (click)="cardFilter.type.planeswalker = !cardFilter.type.planeswalker ; filterCards()"><i
            class="ms ss-fw ms-planeswalker"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.land }"
          ngbTooltip="Land" container="body"
          (click)="cardFilter.type.land         = !cardFilter.type.land         ; filterCards()"><i
            class="ms ss-fw ms-land"></i></button>
      </div>

      <div class="mt-4">Additional Types</div>
      <div class="types-wrapper d-flex">
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.tribal }"
          ngbTooltip="Tribal" container="body"
          (click)="cardFilter.type.tribal       = !cardFilter.type.tribal       ; filterCards()"><i
            class="ms ss-fw ms-tribal"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.plane }"
          ngbTooltip="Plane" container="body"
          (click)="cardFilter.type.plane        = !cardFilter.type.plane        ; filterCards()"><i
            class="ms ss-fw ms-plane"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.phenomenon }"
          ngbTooltip="Phenomenon" container="body"
          (click)="cardFilter.type.phenomenon   = !cardFilter.type.phenomenon   ; filterCards()"><i
            class="ms ss-fw ms-phenomenon"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.scheme }"
          ngbTooltip="Scheme" container="body"
          (click)="cardFilter.type.scheme       = !cardFilter.type.scheme       ; filterCards()"><i
            class="ms ss-fw ms-scheme"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.conspiracy }"
          ngbTooltip="Conspiracy" container="body"
          (click)="cardFilter.type.conspiracy   = !cardFilter.type.conspiracy   ; filterCards()"><i
            class="ms ss-fw ms-conspiracy"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.vanguard }"
          ngbTooltip="Vanguard" container="body"
          (click)="cardFilter.type.vanguard     = !cardFilter.type.vanguard     ; filterCards()"><i
            class="ms ss-fw ms-vanguard"></i></button>
        <button class="btn btn-sm btn-blank text-large" [ngClass]="{ 'text-secondary' : !cardFilter.type.token }"
          ngbTooltip="Token" container="body"
          (click)="cardFilter.type.token        = !cardFilter.type.token        ; filterCards()"><i
            class="ms ss-fw ms-token"></i></button>
      </div>

      <div class="mt-4 d-flex">
        <span class="flex-grow-1">Converted Mana Cost</span>
        <span class="flex-grow-0">{{ cardFilter.cmc.min }} to {{ cardFilter.cmc.max }}</span>
      </div>
      <div class="formats-wrapper pt-3">
        <npn-slider hide-values [step]="1" [multiRange]="true" [minSelected]="cardFilter.cmc.min"
          [maxSelected]="cardFilter.cmc.max" [min]="0" [max]="20" (onChange)="cardFilter.onChangeCMC($event)"
          class="w-100" (mouseup)="filterCards()">
        </npn-slider>
      </div>

      <div class="mt-4">Sets</div>
      <div class="formats-wrapper d-flex flex-column position-relative">
        <input type="text" class="form-control-sm mt-1" placeholder="Enter a set name or choose from the list"
          [(ngModel)]="cardFilter.search.set" (focus)="cardFilter.showSetsPanel= true"
          (blur)="cardFilter.showSetsPanel = false" (keydown.enter)="null">

        <!-- <div class="card-type-helper"> -->
        <div class="setlist-dropdown" *ngIf="cardFilter.search.set !== '' || cardFilter.showSetsPanel">
          <ng-container *ngFor="let setHeader of cardFilter.setList | cardSetsFilter: cardFilter.search.set">
            <div class="drop-down-item no-hover card-type ps-3 mt-3 mb-1 text-bold" [innerHTML]="setHeader.name"></div>
            <ng-container *ngFor="let set of setHeader.list">
              <button ngbDropdownItem class="pt-1 pb-1 drop-down-item d-flex flex-row align-items-center"
                (mousedown)="cardFilter.addSet($event, set.code, set.name); filterCards()">
                <svg class="set-icon">
                  <use [attr.xlink:href]="'#sets-' + set.icon_code + '-svg'"></use>
                </svg>
                <span class="ms-3" [innerText]="set.name + ' (' + set.code.toUpperCase() + ')'"></span>
              </button>
            </ng-container>
          </ng-container>
        </div>
        <!-- </div> -->

        <div class="selected-sets mt-4" *ngIf="cardFilter.selectedSets.length > 0">
          <ng-container *ngFor="let set of cardFilter.selectedSets; let i = index">
            <div class="selected-set-item mb-2">
              <fa-icon [icon]="faTimesCircle" [fixedWidth]="true" class="me-2"
                (click)="cardFilter.removeSet(i); filterCards()">
              </fa-icon>
              <span class="" [innerText]="set.name"></span>
            </div>
          </ng-container>
        </div>
      </div>


    </div>

    <div class="card-body no-hover card-list p-0 d-flex flex-column overflow-hidden">


      <ng-container *ngIf="true">
      <!-- <ng-container *ngIf="simpleCardList; else loadingCards"> -->

        <ng-template #tipContent>
          <div class="text-large">Card Search</div>
          <ul class="text-start">
            <li>Type to search</li>
            <li>Use UP or DOWN-arrows to move up and down in search result</li>
            <li>Use LEFT or RIGHT-arrows to change card set from the set list</li>
            <li>Press ENTER to add card to the Main Deck</li>
            <li>Press Ctrl + Enter to add card to the Sideboard</li>
            <li>Press Shift (Left) + ENTER to add card to the Maybeboard</li>
          </ul>
        </ng-template>

        <div class="input-group input-group-sm px-2 pt-2" [ngbTooltip]="tipContent" container="body">
          <!-- <div class="input-group-prepend">
            <button class="btn btn-sm btn-outline-secondary"
              (click)="cardFilter.searchByName = !cardFilter.searchByName">
              {{ cardFilter.searchByName ? 'Name' : 'Text' }}
            </button>
          </div> -->
          <input #cardSearchInput type="text" class="form-control fix-rounded-right"
            [(ngModel)]="cardFilter.search.string" placeholder="Start typing to find cards" (input)="onSearch()">
          <!-- <div class="input-group-append">
            <button class="btn btn-sm btn-outline-secondary" (click)="cardFilter.show = !cardFilter.show">
              <fa-icon [fixedWidth]="true" [ngClass]="{ 'text-orange' : cardFilter.show }" [icon]="faFilter"></fa-icon>
            </button>
          </div> -->
        </div>

        <ng-container *ngIf="filteredCards.length > 0">

          <div class="text-secondary px-3 py-2 white-space-nowrap overflow-hidden" [style.minHeight.rem]="2.25"
            [style.maxHeight.rem]="2.25">Set: {{
            selectedCardSet }}</div>

          <div class="set-selector-wrapper d-flex align-items-center px-3 py-3 border-bottom border-secondary-dark"
            *ngIf="showSetSelector" [style.minHeight.rem]="3" [style.maxHeight.rem]="3">
            <ng-container *ngFor="let printing of selectedCardPrintings; let i = index">
              <div class="btn btn-sm btn-blank me-1 p-1 printing-button" [ngbTooltip]="printing.set" container="body"
                (click)="selectedPrintingIndex = i" (dblclick)="addCardToDeck(DECKPART.MAIN)"
                [ngClass]="{ 'btn-main selected selected-printing' : i === selectedPrintingIndex }"
                [id]="'printingIndex-' + i">
                <!-- <i class="ss ss-fw ss-{{ printing.keyruneCode.toLowerCase() }} text-xlarge"></i> -->
                <!-- <svg class="set-icon">
                  <use [attr.xlink:href]="'#sets-' + printing.setCode + '-svg'"></use>
                </svg> -->
                <img [src]="printing.iconUrl" class="set-icon svg">
                <!-- <object type="image/svg+xml" [data]="printing.iconUrl" class="set-icon"></object> -->
              </div>
            </ng-container>
          </div>

          <div class="list-group flex-grow-1 overflow-hidden overflow-y-auto search-result">
            <ng-container *ngFor="let card of searchResult; let i = index;">
              <div class="list-item d-flex align-items-center pe-1" [id]="'cardIndex-' + i"
                [ngClass]="{ 'selected selected-card' : i === selectedCardIndex }"
                (click)="selectedCardIndex = i; selectedCardIndex === i ? null : selectedPrintingIndex = 0;">
                <span class="flex-grow-1" [innerHtml]="card.name" (dblclick)="addCardToDeck(DECKPART.MAIN)"></span>

                <div class="action-wrapper d-flex align-items-center" *ngIf="i === selectedCardIndex">
                  <div class="btn btn-sm btn-main me-1" ngbTooltip="Add to main deck (enter)" container="body"
                    (click)="addCardToDeck(DECKPART.MAIN)">M</div>
                  <div class="btn btn-sm btn-warning me-1" ngbTooltip="Add to sideboard (ctrl + enter)" container="body"
                    (click)="addCardToDeck(DECKPART.SIDEBOARD)">SB</div>
                  <div class="btn btn-sm btn-secondary" ngbTooltip="Add to maybies (shift + enter)" container="body"
                    (click)="addCardToDeck(DECKPART.MAYBEBOARD)">MB</div>
                </div>
              </div>
            </ng-container>
          </div>

        </ng-container>

      </ng-container>

      <ng-template #loadingCards>

        <div class="w-100 d-flex align-items-center justify-content-center flex-column">
          <div class="lds-spinner text-secondary mt-3">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>

          <div class="text-secondary py-3 text-center">loading cards...</div>
        </div>

      </ng-template>

    </div>

  </div>

  <ng-container *ngIf="deckList$ | async as deck">
    <ng-container *ngIf="workingVersion$ | async as workingDeck">
      <div #deckView id="mainView" class="card flex-grow-1"
        style="margin-left: -1px; min-width: 20rem; max-width: 100%;">

        <div class="card-header d-flex align-items-center">

          <div class="text-large text-secondary me-4" [innerHtml]="deck.name"></div>

          <div class="btn-group btn-group-sm me-4 version-selector">
            <div class="btn-group" ngbDropdown role="group" [ngbTooltip]="workingDeck.isLocked ? 'Version locked for editing' : 'Unlocked for editing'" container="body">
              <button class="btn btn-sm btn-dark round-left" ngbDropdownToggle>
                <fa-icon [icon]="workingDeck.isLocked ? faLock : faUnlock" [fixedWidth]="true"
                  class="me-1" [ngClass]="{ 'text-red' : workingDeck.isLocked }"></fa-icon>
                <span [innerHtml]="deck.name === workingDeck.name ? 'origin' : workingDeck.name" class="me-2"></span>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <button ngbDropdownItem [routerLink]="'/decks/edit/' + deck.docId + '/version/origin'"><span
                    class="text-secondary">origin</span></button>
                <button ngbDropdownItem *ngFor="let version of deck.versions"
                  [routerLink]="'/decks/edit/' + deck.docId + '/version/' + version.versionDocId">{{ version.versionName }}</button>
              </div>
            </div>
            <button type="button" class="btn btn-sm btn-dark round-right" (click)="generateVersion()" ngbTooltip="Generate new version" container="body">
              <fa-icon [icon]="faPlus" [fixedWidth]="true"></fa-icon>
            </button>
          </div>

          <div class="btn btn-sm btn-blank" [ngClass]="{ 'text-secondary' : view.value !== mainView }"
            *ngFor="let view of mainViews" (click)="changeView(view)" [ngbTooltip]="view.tooltip" container="body">
            <fa-icon [icon]="view.icon" [fixedWidth]="true"></fa-icon>
          </div>

          <div class="btn btn-sm btn-blank text-secondary" (click)="openGoldFishMode()" ngbTooltip="Take it for a spin (goldfish)"
            container="body">
            <fa-icon [icon]="iconPlayTest" [fixedWidth]="true"></fa-icon>
          </div>

          <div class="btn btn-sm btn-blank text-secondary" (click)="downloadDeckList(workingDeck)" ngbTooltip="Download"
            container="body">
            <fa-icon [icon]="faFileDownload" [fixedWidth]="true"></fa-icon>
          </div>

          <div class="btn btn-sm btn-blank text-secondary" (click)="importDeckList(workingDeck)" ngbTooltip="Import"
            container="body">
            <fa-icon [icon]="faFileUpload" [fixedWidth]="true"></fa-icon>
          </div>

          <div class="btn btn-sm btn-blank text-secondary" (click)="shareVersion()" ngbTooltip="Share this version of the deck"
            container="body">
            <fa-icon [icon]="faShare" [fixedWidth]="true"></fa-icon>
          </div>

        </div>



        <div class="card-body overflow-hidden overflow-y-auto d-flex flex-row no-hover p-0">

          <ng-container [ngSwitch]="mainView">

            <app-deck-list-editor *ngSwitchCase="'deck-list-editor'" [deckList]="workingDeck" [cardPreviewer]="true">
            </app-deck-list-editor>

            <app-deck-visual-editor *ngSwitchCase="'deck-visual-editor'" [deckList]="workingDeck">
            </app-deck-visual-editor>

            <app-deck-pic-editor *ngSwitchCase="'deck-picture'" [deckList]="workingDeck">
            </app-deck-pic-editor>

            <app-deck-details *ngSwitchCase="'deck-details'" [deckList]="workingDeck" [originDeck]="deck">
            </app-deck-details>

          </ng-container>

        </div>

      </div>

    </ng-container>
  </ng-container>

</div>

<ng-template #newVersionNameModal let-modal>
  <div class="modal-body">
    <input type="text" class="form-control w-100 {{ newVersionNameValid ? 'is-valid' : 'is-invalid' }}"
      placeholder="Version name" [(ngModel)]="newVersionName"
      (keydown.enter)="$event.preventDefault(); $event.stopPropagation(); newVersionNameValid ? modal.close() : null">
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-blank text-red" (click)="modal.dismiss()">Cancel</button>
    <button class="btn btn-sm btn-blank" (click)="modal.close()" [disabled]="!newVersionNameValid">Generate
      Version</button>
  </div>
</ng-template>
