import { IEventPlayerDetails } from 'tolaria-cloud-functions/src/_interfaces'

export class EventPlayer {

  private document: IEventPlayerDetails = {
    eventDocId: '',
    playerUid: '',
    playerDocId: '',
    name: '',
    dropped: false,
    disqualified: false,
    haveByeMatch: false,
    wins: 0,
    losses: 0,
    draws: 0,
    matchPoints: 0,
    gamePoints: 0,
    matchWinPercentage: 0,
    gameWinPercentage: 0,
    opponentMatchWinPercentage: 0,
    opponentGameWinPercentage: 0,
    adj_gp: 0,
    adj_gwp: 0,
    adj_mp: 0,
    adj_mwp: 0,
    opponentsDocIds: [],
    deckSubmission: {
      deckPhoto: false,
      deckList: false,
      deckListDocId: null,
      deckVersionDocId: null,
    },
    playedInGroup: ''
  }


  /**
   * Create a new event player document
   * 
   * @param eventId string  the unique document id of the event
   * @param uid     string  the players unique user id
   * @param id      string  the players unique player id
   * @param name    string  the players display name
   * @param group   string  the group to be used with tournament structure > group
   */
  constructor(
    eventId: string,
    uid: string,
    id: string,
    name: string,
    group?: string
  ) {
    this.document.eventDocId = eventId
    this.document.playerUid = uid
    this.document.playerDocId = id
    this.document.name = name
    this.document.playedInGroup = group ? group : ''
  }


  /**
   * Return the full document of this event player
   */
  public get doc(): IEventPlayerDetails {
    return this.document
  }


}