import { IBracketMeta, IEventLog, IMatchData, IMatchPlayer, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { ReportSlipComponent } from '../components/report-slip/report-slip.component';
import { ToastService } from 'src/app/services/toast.service';
import { MessageModalService } from 'src/app/components/modals/message-modal/message-modal.service'
import { PlayerNameService } from 'src/app/services/players/player-name.service';
import { v4 as uuidv4 } from 'node_modules/uuid'
import * as firestore from 'firebase/firestore'
import { firstValueFrom, take } from 'rxjs';
import { CreateEmptyMatchPlayer } from './helpers/utilities/match';

@Injectable({
  providedIn: 'root'
})
export class MatchReportingService {

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly modalService: NgbModal,
    private readonly toast: ToastService,
    private readonly confirm: MessageModalService,
    private readonly playerNames: PlayerNameService,
  ) { }


  private _getMatchMetaForEventLog(updatedBy: IPlayerMini, match: IMatchData) {
    const winnerIs = match.player1.isWinner ? 'player1' : 'player2'

    return {
      roundNumber: match.roundNumber,
      segmentNumber: match.segmentNumber,
      groupName: match.groupName,
      matchDocId: match.docId,
      updatedByName: updatedBy.name.display,
      updatedByUid: updatedBy.uid,
      updatedById: updatedBy.id,
      player1: match.player1.displayName,
      player2: match.player1.displayName,
      winner: match.isDraw
        ? 'Player draws'
        : match[winnerIs ? 'player1' : 'player2'].displayName,
      result: match.isDraw
        ? match.player1.wins === 1 && match.player2.wins === 1 ? '1 - 1' : '0 - 0'
        : match[winnerIs].wins === 2 && match[winnerIs].losses === 1
          ? '2 - 1'
          : match[winnerIs].wins === 2 && match[winnerIs].losses === 0
            ? '2 - 0'
            : match[winnerIs].wins === 1 && match[winnerIs].losses === 0
              ? '1 - 0'
              : 'none'
    }
  }

  private _reportMatch(match: IMatchData, isOrganizer: boolean) {

    // get user reporting
    const updatedBy = this.playerNames.currentPlayersMini
    const matchDocRef = this.firestore.collection('matches').doc(match.docId)
    const eventDocRef = this.firestore.collection('events').doc(match.eventDocId)


    // create log text
    let logText: string = ''

    // if organizer reporting,or opponent is confirming set isReporting to true
    if (isOrganizer) {
      logText = `Match between ${match.player1.displayName} and ${match.player2.displayName} was reported by ${updatedBy.name.display}.`
      match.isReported = true
    }
    else if (match.playerReporting && match.playerReporting.confirmedByInitiator && match.playerReporting.confirmedByOpponent) {
      logText = `Match result <span class="text-bold text-success">ACCEPTED</span> by ${updatedBy.name.display}. Match is now reported.`
      match.isReported = true
    }
    else if (match.playerReporting && match.playerReporting.confirmedByInitiator && match.playerReporting.rejectedByOpponent) {
      logText = `Match result <span class="text-bold text-success">REJECTED</span> by ${updatedBy.name.display}. Match is NOT reported.`
      match.isReported = false
    }
    else {
      logText = `Match result entered by ${updatedBy.name.display}. Waiting for opponent to accept the result slip.`
      match.isReported = false
    }

    // clear report slip opened property
    match.reportSlipOpenedBy = null

    // create a batch for writing
    const batch = this.firestore.firestore.batch()

    batch.set(matchDocRef.ref, match)

    // add log if connected to an event
    if (match.eventDocId !== null) {

      const logEntry: IEventLog = {
        type: 'match-update',
        timestamp: firestore.Timestamp.now().seconds,
        text: logText,
        metadata: this._getMatchMetaForEventLog(updatedBy, match),
      }
      batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logEntry)

      // check if match is a bracket match and advance the winner
      if (match.isType === 'bracket' && match.feedsMatchDocId !== null && match.feedsMatchDocId !== undefined && match.feedsMatchDocId !== '') {
        // collect the winner
        const winner: IMatchPlayer = match.player1.isWinner ? JSON.parse(JSON.stringify(match.player1)) : JSON.parse(JSON.stringify(match.player2))
        // reset stats
        winner.wins = 0
        winner.draws = 0
        winner.losses = 0
        winner.lifePoints = [20]
        winner.handSize = 7
        winner.isWinner = false
        // create update of the next match in the bracket
        batch.update(this.firestore.collection('matches').doc(match.feedsMatchDocId).ref, {
          [`${match.feedsMatchPlayer}`]: winner
        })
      }

      // check if match is a double bracket match and advance the players where applicable
      if (match.isType === 'double-bracket') {
        if (match.winnerGoToMatchDoc !== null && match.winnerGoToMatchDoc !== undefined && match.winnerGoToMatchDoc !== '') {
          // collect the winner
          const winner: IMatchPlayer = match.player1.isWinner ? JSON.parse(JSON.stringify(match.player1)) : JSON.parse(JSON.stringify(match.player2))
          // reset stats
          winner.wins = 0
          winner.draws = 0
          winner.losses = 0
          winner.lifePoints = [20]
          winner.handSize = 7
          winner.isWinner = false
          // create update of the next match in the bracket
          batch.update(this.firestore.collection('matches').doc(match.winnerGoToMatchDoc).ref, {
            [`${match.winnerGoToPlayerSlot}`]: winner
          })
        }
        if (match.loserGoToMatchDoc !== null && match.loserGoToMatchDoc !== undefined && match.loserGoToMatchDoc !== '') {
          // collect the winner
          const loser: IMatchPlayer = match.player1.isWinner ? JSON.parse(JSON.stringify(match.player2)) : JSON.parse(JSON.stringify(match.player1))
          if (!['*** BYE ***', '*** LOSS ***'].includes(loser.playerDocId)) { 
            // reset stats
            loser.wins = 0
            loser.draws = 0
            loser.losses = 0
            loser.lifePoints = [20]
            loser.handSize = 7
            loser.isWinner = false
            // create update of the next match in the bracket
            batch.update(this.firestore.collection('matches').doc(match.loserGoToMatchDoc).ref, {
              [`${match.loserGoToPlayerSlot}`]: loser
            })
          }
        }
      }

    }


    // handle dropping of players here if report slip is finalized and ready to be reported
    if (match.isReported) {

      const dropping: string[] = []
      if (match.player1.drop) { dropping.push(match.player1.playerDocId) }
      if (match.player2.drop) { dropping.push(match.player2.playerDocId) }
      for (const playerDocId of dropping) {

        // flag dropping on the event player document
        batch.update(eventDocRef.collection('players').doc(playerDocId).ref, {
          dropped: true,
        })

        // get minified player document
        const playerMini = this.playerNames.getPlayerById(playerDocId)
        logText = `${playerMini.name.display} <span class="text-bold text-danger">DROPPED</span> as a result of selecting to drop on the report slip.`

        // log dropping
        const logEntry: IEventLog = {
          type: 'attending',
          timestamp: firestore.Timestamp.now().seconds,
          text: logText,
          metadata: {
            playerDocId: playerDocId,
            matchDocId: match.docId,
            round: match.roundNumber,
          },
        }
        batch.set(eventDocRef.collection('log').doc(`log-${uuidv4()}`).ref, logEntry)

      }
    }



    // commit batch
    batch.commit()
      .then(() => this.toast.show('Report slip saved', { classname: 'success-toast' }))
      .catch((e) => this.toast.show(e, { classname: 'error-toast' }))

  }

  /**
   * Open the reportslip for a given match as a modal (fullscreen modal on small screens)
   * for reporting the result.
   *
   * @param config { matchData?: IMatchData, isOrganizer: boolean, matchDocId: string }
   * @returns
   */
  public async openReportSlip(config: { matchData?: IMatchData, isOrganizer: boolean, matchDocId: string }) {

    // set document reference
    const docRef = this.firestore.collection('matches').doc<IMatchData>(config.matchDocId)

    // set initial data
    let match: IMatchData = config.matchData ? JSON.parse(JSON.stringify(config.matchData)) : undefined

    // check if match data is passed
    if (config.matchData === undefined) {
      const matchDoc = await firstValueFrom(docRef.get())
      if (!matchDoc.exists) {
        this.toast.show('Match document not found!', { classname: 'error-toast' })
        return
      }

      // set data
      match = matchDoc.data()

    }

    // check if already open
    if (match.reportSlipOpenedBy !== undefined && match.reportSlipOpenedBy !== null && match.reportSlipOpenedBy !== this.playerNames.currentPlayersMini.id) {
      this.toast.show(`Report slip already open. (Opened by ${this.playerNames.getPlayerById(match.reportSlipOpenedBy)?.name?.display})`)
      return
    }

    // check if already reported
    if (match.isReported) {

      const proceed = await this.confirm.open({
        type: 'warning',
        title: 'Match already reported',
        message: `
        <p>This match has already been reported, are you sure you want to continue editng the result?<p>
        <p class="text-italic text-warning">If the result of a match in a previous round is changed, the standings will be affected.
        If any rounds after the round for which this match below has already been paired, nothing will be changed in those pairings.</p>
        `,
        buttons: [
          {
            type: 'dismiss',
            value: 'dismiss',
            text: 'Cancel'
          },
          {
            type: 'close',
            value: 'continue',
            text: 'Continue'
          }
        ]
      })
        .then((response) => {
          if (response === 'continue') {
            return true
          }
          else {
            return false
          }
        })
        .catch((e) => {
          console.error(e)
          return false
        })

      if (!proceed) {
        return
      }

    }

    if (match.reportSlipOpenedBy === undefined || match.reportSlipOpenedBy === null) {
      // mark the match document with reportSlipOpenedBy
      await docRef.update({ reportSlipOpenedBy: this.playerNames.currentPlayersMini.id })
        .then(() => console.log('[MatchReportingService] --> report slip marked as open', match.docId))
        .catch((e) => console.error(e))
    }

    const modalRef = this.modalService.open(ReportSlipComponent, {
      fullscreen: window.innerWidth < 500,
      backdrop: 'static',
      keyboard: false,
    })
    modalRef.componentInstance.match = match
    modalRef.componentInstance.isOrganizer = config.isOrganizer


    modalRef.result
      .then(
        (reportSlipData: IMatchData) => {
          console.log(`[MatchReportingService] --> report slip for match ${match.docId} closed.`)

          if (config.isOrganizer) {
            this._reportMatch(reportSlipData, config.isOrganizer)
          }
          /**
           * The reporting should be between players and the initiator should report in steps
           * and then confirm the result.
           * When the result is confirmed, the match document should hold a property that all
           * players will listen to and when a match with this state is found, the confirmation
           * dialog should be opened.
           * The dialog should hold two actions: CONFIRM => REPORT or DECLINE => RESET SLIP
           */
          else {
            const currentPlayer = this.playerNames.currentPlayersMini

            // reporting initiated by a player
            if (!reportSlipData.playerReporting || reportSlipData.playerReporting.waitingForPlayerDocId.length === 0) {
              const playerIs = match.isType === 'swiss-team'
                ? match.player1.teamPlayerDocIds.includes(currentPlayer.id) ? 'player1' : 'player2'
                : match.player1.playerDocId === currentPlayer.id ? 'player1' : 'player2'
              const opponentIs = playerIs === 'player1' ? 'player2' : 'player1'
              reportSlipData.playerReporting = {
                initiatedByPlayerDocId: currentPlayer.id,
                initiatedAt: firestore.Timestamp.now().seconds,
                confirmedByInitiator: true,
                waitingForPlayerDocId: match.isType === 'swiss-team'
                  ? match[opponentIs].teamPlayerDocIds
                  : [match[opponentIs].playerDocId],
              }
              // remove properties that reflect opponents answer
              // these will be added again when the opponent answers
              delete reportSlipData.playerReporting.confirmedByOpponent
              delete reportSlipData.playerReporting.rejectedByOpponent
            }
            // report slip answered by opponent
            if (reportSlipData.playerReporting && reportSlipData.playerReporting.waitingForPlayerDocId.includes(currentPlayer.id)) {
              // remove waiting for property
              reportSlipData.playerReporting.waitingForPlayerDocId = []
            }

            this._reportMatch(reportSlipData, config.isOrganizer)

          }
        },
        async () => {
          await docRef.update({ reportSlipOpenedBy: null })
          console.log(`[MatchReportingService] --> report slip for match ${match.docId} dismissed.`)
        }
      )

  }

  public reactivateMatch(match: IMatchData): void {

    if (match.isByeMatch || match.isLossMatch) {
      this.toast.show(`You are not allowed to change a ${match.isByeMatch ? 'BYE' : 'LOSS'} match!`, { classname: 'error-toast' })
      return
    }

    // create a batch for writing
    const batch = this.firestore.firestore.batch()

    // add match update
    batch.update(this.firestore.collection('matches').doc(match.docId).ref, {
      deleted: false,
    })

    // add log if connected to an event
    if (match.eventDocId !== null) {
      const docRef = this.firestore.collection('events').doc(match.eventDocId)
      const updatedBy = this.playerNames.currentPlayersMini
      const logText: IEventLog = {
        type: 'match-update',
        timestamp: firestore.Timestamp.now().seconds,
        text: `Match between ${match.player1.displayName} and ${match.player2.displayName} was reactivated by ${updatedBy.name.display}.`,
        metadata: this._getMatchMetaForEventLog(updatedBy, match),
      }
      batch.set(docRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)
    }

    batch.commit()
      .then(() => this.toast.show('Match has been marked as active', { classname: 'success-toast' }))
      .catch((e) => this.toast.show(e, { classname: 'error-toast' }))

  }

  public async inactivateMatch(match: IMatchData) {

    if (match.isByeMatch || match.isLossMatch) {
      this.toast.show(`You are not allowed to change a ${match.isByeMatch ? 'BYE' : 'LOSS'} match!`, { classname: 'error-toast' })
      return
    }

    const result = await this.confirm.open({
      type: 'warning',
      title: 'Inactivate match?',
      message: `Are you sure you want to <b>inactivate this match</b>?<br>
      <span class="text-italic">Inactivating a match will remove the match from any standings calculation
      and the players will no longer be able to access the match.</span>`,
      buttons: [
        {
          text: 'Cancel',
          type: 'dismiss',
          value: null,
        },
        {
          text: 'Inactivate',
          type: 'close',
          value: 'inactivate-match'
        }
      ],
    })

    if (result === 'inactivate-match') {

      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // add match update
      batch.update(this.firestore.collection('matches').doc(match.docId).ref, {
        deleted: true,
      })

      // add log if connected to an event
      if (match.eventDocId !== null) {
        const docRef = this.firestore.collection('events').doc(match.eventDocId)
        const updatedBy = this.playerNames.currentPlayersMini
        const logText: IEventLog = {
          type: 'match-update',
          timestamp: firestore.Timestamp.now().seconds,
          text: `Match between ${match.player1.displayName} and ${match.player2.displayName} was inactivated by ${updatedBy.name.display}.`,
          metadata: this._getMatchMetaForEventLog(updatedBy, match),
        }
        batch.set(docRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)
      }

      batch.commit()
        .then(() => this.toast.show('Match has been marked as inactive', { classname: 'success-toast' }))
        .catch((e) => this.toast.show(e, { classname: 'error-toast' }))
    }

  }

  public async resetReportSlip(match: IMatchData, bracketMeta?: IBracketMeta[]) {

    if (match.isByeMatch || match.isLossMatch) {
      this.toast.show(`You are not allowed to change a ${match.isByeMatch ? 'BYE' : 'LOSS'} match!`, { classname: 'error-toast' })
      return
    }

    const result = await this.confirm.open({
      type: 'warning',
      title: 'Reset report slip?',
      message: `Are you sure you want to <b>reset</b> the report slip?<br>
      <span class="">This will reset any reported result and this cannot be undone!</span><br>
      <span class="">If a player dropped during reporting, a manual undrop needs to be initiated!</span>`,
      buttons: [
        {
          text: 'Cancel',
          type: 'dismiss',
          value: null,
        },
        {
          text: 'Reset',
          type: 'close',
          value: 'reset-report-slip'
        }
      ],
    })

    if (result === 'reset-report-slip') {

      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // add match update
      batch.update(this.firestore.collection('matches').doc(match.docId).ref, {
        isReported: false,
        isDraw: false,
        'player1.isWinner': false,
        'player1.wins': 0,
        'player1.losses': 0,
        'player1.draws': 0,
        'player1.drop': false,
        'player2.isWinner': false,
        'player2.wins': 0,
        'player2.losses': 0,
        'player2.draws': 0,
        'player2.drop': false,
        playerReporting: null,
      })

      // add log if connected to an event
      if (match.eventDocId !== null) {
        const docRef = this.firestore.collection('events').doc(match.eventDocId)
        const updatedBy = this.playerNames.currentPlayersMini
        const logText: IEventLog = {
          type: 'match-update',
          timestamp: firestore.Timestamp.now().seconds,
          text: `The report slip for the match between ${match.player1.displayName} and ${match.player2.displayName} was reset by ${updatedBy.name.display}.`,
          metadata: this._getMatchMetaForEventLog(updatedBy, match),
        }
        batch.set(docRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)
      }

      // handle bracket matrix updates if needed
      if (match.isType === 'bracket' && match.feedsMatchDocId !== null && match.feedsMatchDocId !== undefined && match.feedsMatchDocId !== '') {

        if (bracketMeta === undefined) {
          this.toast.show(`Bracket matrix missing, can't reset report slip`, { classname: 'error-toast' })
          return
        }

        // create the match chain to be able to handle all affected matches
        // 1. Get the round for the match being reset
        // 2. Go through all rounds from match round until end and add the feeds to the chain
        const chainLength = bracketMeta.length - bracketMeta.find(r => r.matches.map(m => m.docId).includes(match.docId)).round
        const chainedMatchDocIds: string[] = []
        const matchList: IMatchData[] = bracketMeta.map(i => i.matches).flat(1)
        let feedingMatchDocId = match.docId
        do {

          // get match that feeds next round
          const feedingMatch = matchList.find(i => i.docId === feedingMatchDocId)

          // get match to update
          const feededMatch = matchList.find(i => i.docId === feedingMatch.feedsMatchDocId)

          // add iteration to do array
          chainedMatchDocIds.push(feedingMatch.feedsMatchDocId)

          // update id for the next iteration of the loop
          feedingMatchDocId = feedingMatch.feedsMatchDocId

          // add match update
          batch.update(this.firestore.collection('matches').doc(feededMatch.docId).ref, {
            isReported: false,
            isDraw: false,
            'player1.isWinner': false,
            'player1.wins': 0,
            'player1.losses': 0,
            'player1.draws': 0,
            'player1.drop': false,
            'player2.isWinner': false,
            'player2.wins': 0,
            'player2.losses': 0,
            'player2.draws': 0,
            'player2.drop': false,
            [`${feedingMatch.feedsMatchPlayer}`]: CreateEmptyMatchPlayer(),
            playerReporting: null,
          })



        }
        while (chainedMatchDocIds.length < chainLength)


        // log about updated matches as well
        if (chainedMatchDocIds.length > 0) {
          const docRef = this.firestore.collection('events').doc(match.eventDocId)
          const logText: IEventLog = {
            type: 'match-update',
            timestamp: firestore.Timestamp.now().seconds,
            text: `Due to the reset of bracket match, ${chainedMatchDocIds.length} succeeding matches were also changed.`,
            metadata: chainedMatchDocIds.reduce((current, item, index) => {
              current[`match-feed-${index}`] = item
              return current
            }, {}),
          }
          batch.set(docRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)
        }

      }


      // commit batch
      batch.commit()
        .then(() => this.toast.show('Report slip has been reset', { classname: 'success-toast' }))
        .catch((e) => this.toast.show(e, { classname: 'error-toast' }))
    }

  }

  public async deleteMatch(match: IMatchData) {

    if (match.isByeMatch || match.isLossMatch) {
      this.toast.show(`You are not allowed to change a ${match.isByeMatch ? 'BYE' : 'LOSS'} match!`, { classname: 'error-toast' })
      return
    }

    const result = await this.confirm.open({
      type: 'danger',
      title: 'Delete Match Document?',
      message: `Are you sure you want to <b>DELETE</b> the match document?<br>
      This will completely delete the document!
      This cannot be undone and the match document will <b>NOT</b> be possible to recreate!`,
      buttons: [
        {
          text: 'Cancel',
          type: 'dismiss',
          value: null,
        },
        {
          text: 'Delete match',
          type: 'close',
          value: 'delete-match-document'
        }
      ],
      confirmInput: true,
      confirmText: 'DELETE MATCH'
    })

    if (result === 'delete-match-document') {
      // create a batch for writing
      const batch = this.firestore.firestore.batch()

      // add match update
      batch.delete(this.firestore.collection('matches').doc(match.docId).ref)

      // add log if connected to an event
      if (match.eventDocId !== null) {
        const docRef = this.firestore.collection('events').doc(match.eventDocId)
        const updatedBy = this.playerNames.currentPlayersMini
        const logText: IEventLog = {
          type: 'match-update',
          timestamp: firestore.Timestamp.now().seconds,
          text: `Match document for the match between ${match.player1.displayName} and ${match.player2.displayName} was DELETED by ${updatedBy.name.display}.`,
          metadata: this._getMatchMetaForEventLog(updatedBy, match),
        }
        batch.set(docRef.collection('log').doc(`log-${uuidv4()}`).ref, logText)
      }

      batch.commit()
        .then(() => this.toast.show('Match document deleted', { classname: 'success-toast' }))
        .catch((e) => this.toast.show(e, { classname: 'error-toast' }))
    }

  }



}
