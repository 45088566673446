import { AuthService } from 'src/app/services/auth.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { IPlayerMinified, IPlayerNameMap, IPlayerMini } from 'tolaria-cloud-functions/src/_interfaces/player.interfaces';
import { IClub, IPlayerDetails, IUser } from 'tolaria-cloud-functions/src/_interfaces';

interface IPlayerMeta {
  id: string
  mini: IPlayerMini

  isClubMember: boolean
  isHero: boolean
  online: 'online' | 'away' | 'offline'
  identifiers: {
    dingus: boolean
    hero: boolean
    guardian: boolean
    organizer: boolean
    admin: boolean
    shark: boolean
    downToPlay: boolean
  }
  clubs: IClub[]
  clubNames: string
  statusMessage: string
}
interface IOrganizers {
  [key: string]: any
}
@Injectable({
  providedIn: 'root'
})
export class PlayerNameService {

  public serviceReadyCheck$ = new BehaviorSubject<boolean>(false)
  public serviceReady$ = new BehaviorSubject<boolean>(false)
  private _playersReady$ = new BehaviorSubject<boolean>(false)
  private _organizersReady$ = new BehaviorSubject<boolean>(false)
  public playerMinis$ = new BehaviorSubject<IPlayerMini[]>([])
  public playerNames$ = new BehaviorSubject<IPlayerMinified>({})
  public playerNamesList$ = new BehaviorSubject<string[]>([])
  public playerNamesListMap$ = new BehaviorSubject<IPlayerNameMap>({})
  public currentUserPlayerDoc$ = new BehaviorSubject<IPlayerDetails>(null)
  private currentUserPlayerDocSub: Subscription
  private authSub: Subscription
  private organizerListSub: Subscription
  private playerListSub: Subscription
  private _organizerPlayerDocIds$ = new BehaviorSubject<string[]>([])

  constructor(
    private readonly firestore: AngularFirestore,
    private readonly authService: AuthService,
  ) {

    console.log('[PlayerNameService] --> Construction')

    if (this.authSub) { this.authSub.unsubscribe() }

    this.authSub = this.authService.isLoggedIn$.subscribe(loggedIn => {
      if (loggedIn) {
        console.log('[PlayerNameService] --> user logged in.', this.authService.user)
        this.init()
      }
      if (!loggedIn) {
        console.log('[PlayerNameService] --> user logged out.', this.authService.user)
        this.clearData()
      }
    })


  }

  private init(): void {

    if (this.currentUserPlayerDoc$.getValue() === null) {
      if (this.currentUserPlayerDocSub) {
        this.currentUserPlayerDocSub.unsubscribe()
      }
      console.log('[PlayerNameService] --> Creating player document subscription')
      const playerDocReference = this.firestore.collection('players').doc<IPlayerDetails>(this.authService.user.playerId)
      this.currentUserPlayerDocSub = playerDocReference.valueChanges().subscribe(doc => {
        console.log('[PlayerNameService] --> Player document emitted a value', doc)
        this.currentUserPlayerDoc$.next(doc)
      })
    }

    this.currentUserPlayerDoc$.subscribe(i => {
      console.log('[PlayerNameService] --> current user player doc emitted a new value:', i)
      if (i !== null) {
        this.initObservers()
      }
    })

    combineLatest([this._organizersReady$, this._playersReady$, this.currentUserPlayerDoc$])
      .subscribe(([oReady, pReady, playerDoc]) => {
        console.log('[PlayerNameService] --> ready state emitted:', { organizersReady: oReady, playersReady: pReady })
        if (oReady && pReady && playerDoc !== null && this.serviceReady$.getValue() === false && this.serviceReadyCheck$.getValue() === false) {
          this.serviceReady$.next(true)
          this.serviceReadyCheck$.next(true)
        }
      })

  }

  private clearData(): void {
    console.log('[PlayerNameService] --> clearing data')
    this.playerMinis$.next([])
    this.playerNames$.next({})
    this.playerNamesList$.next([])
    this.playerNamesListMap$.next({})
    this.serviceReadyCheck$.next(false)
    this.serviceReady$.next(false)
    this._playersReady$.next(false)
    this._organizersReady$.next(false)
    if (this.organizerListSub) {
      this.organizerListSub.unsubscribe()
      this.organizerListSub = undefined
    }
    if (this.playerListSub) {
      this.playerListSub.unsubscribe()
      this.playerListSub = undefined
    }
    if (this.currentUserPlayerDocSub) {
      this.currentUserPlayerDocSub.unsubscribe()
      this.currentUserPlayerDocSub = undefined
    }
    this.currentUserPlayerDoc$.next(null)
  }

  private initObservers(): void {
    console.log('[PlayerNameService] --> initializing observers')
    console.log('[PlayerNameService] --> creating subscription for the minified organizers list')
    if (this.organizerListSub) { this.organizerListSub.unsubscribe() }
    this.organizerListSub = this.firestore
      .collection('collections')
      .doc<IOrganizers>('organizer-list')
      .valueChanges()
      .subscribe(i => {
        if (i !== undefined && i !== null && i.playerUids) {
          console.log('[PlayerNameService] --> Organizer list emitted a value:', i.playerUids)
          this._organizerPlayerDocIds$.next(i.playerUids)
          // update ready state where needed
          if (this._organizersReady$.getValue() === false) {
            console.log('[PlayerNameService] --> Organizer list ready!')
            this._organizersReady$.next(true)
          }
        }
      })

    console.log('[PlayerNameService] --> creating subscription for the minified players list')
    if (this.playerListSub) { this.playerListSub.unsubscribe() }
    this.playerListSub = this.firestore
      .collection('collections')
      .doc('players')
      .collection('minified')
      .valueChanges()
      .subscribe(async (i) => {

        let players: any = {}
        let playerNamesMap: any = {}
        let playerMinis: IPlayerMini[] = []

        for await (const batch of i as any) {
          // remove unwanted properties of the minified documents
          delete batch.index
          delete batch.playerDocIds
          delete batch.id

          for (const [key, value] of Object.entries(batch)) {
            const p = value as any
            p.id = key
            players[key] = p
            playerNamesMap[p.name.display ? p.name.display : `${p.name.first.trim()} ${p.name.last.trim()}`] = key
            p.name.display = p.name.display ? p.name.display : `${p.name.first.trim()} ${p.name.last.trim()}`
            playerMinis.push(p)
          }

        }

        this.playerMinis$.next(playerMinis)
        this.playerNames$.next(players)
        this.playerNamesListMap$.next(playerNamesMap)
        this.playerNamesList$.next(Object.entries(players).map((i: any) => i[1].name.display ? i[1].name.display : `${i[1].name.first.trim()} ${i[1].name.last.trim()}`).sort((a, b) => a.localeCompare(b)))
        console.log('[PlayerNameService] --> List emitted value --> ', { playerNames: this.playerNames$.getValue(), playerNamesList: this.playerNamesList$.getValue(), playerNamesListMap: this.playerNamesListMap$.getValue(), playerMinis: this.playerMinis$.getValue() })

        // update ready state where needed
        if (this._playersReady$.getValue() === false) {
          this._playersReady$.next(true)
          console.log('[PlayerNameService] --> Player list ready!', playerMinis)
        }
      })

  }

  public getPlayerName(playerDocId: string): string | null {
    const player = this.playerNames$.getValue()[playerDocId]
    if (player) {
      return player.name.display
        ? player.name.display
        : `${player.name.first} ${player.name.last}`
    }
    else {
      return null
    }
  }

  public getPlayerAvatar(playerDocId: string): string | null {
    const player = this.playerNames$.getValue()[playerDocId]
    if (player) {
      return player.avatar
    }
    else {
      return null
    }
  }

  public getPlayerNames(playerDocIds: string[]): string[] {

    const playerNames: string[] = []

    for (const playerDocId of playerDocIds) {

      const player = this.playerNames$.getValue()[playerDocId]
      if (player) {
        const name = player.name.display
          ? player.name.display
          : `${player.name.first} ${player.name.last}`
        playerNames.push(name)
      }
    }

    return playerNames

  }

  public getPlayerAvatars(playerDocIds: string[]): string[] {

    const playerAvatars: string[] = []

    for (const playerDocId of playerDocIds) {
      const player = this.playerNames$.getValue()[playerDocId]
      if (player) {
        playerAvatars.push(player.avatar)
      }
    }

    return playerAvatars

  }

  /**
   * Get a list of all the players that can organize events.
   * aka they have the role ADMIN or ORGANIZER.
   *
   * @returns IPlayerMini[]
   */
  public getPlayersWithOrganizerRole(): IPlayerMini[] {
    const players: IPlayerMini[] = []
    const ids = this._organizerPlayerDocIds$.getValue()
    for (const id of ids) {
      if (this.getPlayerById(id)) {
        players.push(this.getPlayerById(id))
      }
    }
    return players
  }

  /**
   * Get a minified player document by player name
   *
   * @param name string
   * @returns IPlayerMini
   */
  public getPlayerByName(name: string): IPlayerMini {
    const id = this.playerNamesListMap$.getValue()[name]
    return this.playerNames$.getValue()[id]
  }

  /**
   * Get a minified player document by player id
   *
   * @param id string — playerDocId
   * @returns IPlayerMini
   */
  public getPlayerById(id: string): IPlayerMini {
    let player = this.playerNames$.getValue()[id]
    if (player === undefined) {
      return null
    }
    return player
  }

  /**
   * Get a minified player document by email address
   *
   * @param email string — email
   * @returns IPlayerMini
   */
  public getPlayerByEmail(email: string): IPlayerMini | null {
    let mini = this.playerMinis$.getValue().find(i => i.email === email)
    return mini === undefined
      ? null
      : mini
  }

  /**
   * Get a minified player document by player uid
   *
   * @param id string — playerUid
   * @returns IPlayerMini
   */
  public getPlayerByUid(id: string): IPlayerMini {
    const minis = Object.entries(this.playerNames$.getValue()).map(i => i[1])
    return minis.find(i => i.uid === id)
  }

  // public getPlayerMeta(id: string): Promise<IPlayerMeta> {
  //   return new Promise(async (resolve) => {

  //     const __mapPlayerMeta = async (player: IPlayerMini, playerDoc: IPlayerDetails): Promise<IPlayerMeta> => {

  //       const meta: IPlayerMeta = {
  //         id: player.id,
  //         mini: player,

  //         isClubMember: false,
  //         isHero: false,
  //         online: 'offline',
  //         identifiers: {
  //           dingus: false,
  //           hero: false,
  //           guardian: false,
  //           organizer: false,
  //           admin: false,
  //           shark: false,
  //           downToPlay: false,
  //         },
  //         clubs: [],
  //         clubNames: '',
  //         statusMessage: '',
  //       }

  //       // add club affiliation
  //       if (playerDoc?.clubDocIds && playerDoc.clubDocIds.length > 0) {
  //         // get clubs
  //         const snapClubs = await firstValueFrom(this.firestore.collection<IClub>('clubs', ref => ref.where('docId', 'in', playerDoc.clubDocIds)).get())
  //         // add info about clubs to meta
  //         if (!snapClubs.empty) {
  //           meta.isClubMember = true
  //           meta.clubs = snapClubs.docs.map(i => i.data())
  //           meta.clubNames = snapClubs.docs.map(i => i.data().name).join(', ')
  //         }
  //       }




  //       return meta

  //     }

  //     // check if player meta exist and return it if so
  //     if (this.playerMetas$.getValue() !== null) {
  //       if (this.playerMetas$.getValue().find(i => i.id === id)) {
  //         return this.playerMetas$.getValue().find(i => i.id === id)
  //       }
  //     }

  //     // get player document
  //     const snap = await firstValueFrom(this.firestore.collection('players').doc<IPlayerDetails>(id).get())

  //     // check snap
  //     if (!snap.exists) {
  //       // handle non existing player
  //     }

  //     // store document
  //     const playerDoc = snap.data()


  //     // map player meta
  //     const playerMeta = await __mapPlayerMeta(this.getPlayerById(id), playerDoc)

  //     resolve(playerMeta)


  //   })
  // }


  /**
   * Get the minified player document for the current user
   *
   * @returns IPlayerMini
   */
  public get currentPlayersMini(): IPlayerMini {
    return this.playerNames$.getValue()[this.authService.user.playerId]
  }

  public get currentPlayerIsHero(): boolean {
    const player = this.currentUserPlayerDoc$.getValue()
    return player?.identifiers?.isHero || player?.tolariaSupportUntil > Math.floor(Date.now() / 1000)
  }

  public get currentPlayerIsDrakeGuild(): boolean {
    const player = this.currentUserPlayerDoc$.getValue()
    return player?.identifiers?.isDrakeGuild
  }

  public get currentPlayerHasMatchRoomBackground(): boolean {
    return this.currentUserPlayerDoc$.getValue() === null ? false : this.currentUserPlayerDoc$.getValue().matchRoomBackgroundUrl !== undefined
  }

  public get currentPlayersMatchRoomBackgroundUrl(): string {
    return this.currentPlayerHasMatchRoomBackground ? this.currentUserPlayerDoc$.getValue().matchRoomBackgroundUrl : ''
  }

  public get currentPlayerIsOrganizer(): boolean {
    return this.authService.user.role === 'organizer'
  }

  public get currentPlayerIsAdmin(): boolean {
    return this.authService.user.role === 'admin'
  }

}
